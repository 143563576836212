import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "percent" }

import { computed, reactive } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgressBar',
  props: {
  progress: {
    type: Number,
    required: true,
  },
},
  setup(__props) {

const props = __props;
const state = reactive({
  styles: computed(() => ({
    '--percent': `${props.progress}%`,
  })),
  classes: computed(() => ({
    active: props.progress > 0,
  })),
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["progress-wrapper", state.classes]),
    style: _normalizeStyle(state.styles)
  }, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "bar" }, null, -1)),
    _createElementVNode("span", _hoisted_1, _toDisplayString(props.progress) + "%", 1)
  ], 6))
}
}

})