<template>
  <li
    ref="background"
    class="drawer-item"
    :class="{ full: isFull, modal: isPresentModal }"
    @mousedown="onMouseDown"
    @touchdown="onMouseDown"
  >
    <div class="container" :style="state.styles" @click.stop="">
      <component
        :is="payload.component"
        :id="payload.id"
        class="contents"
        v-model:data="payload.data"
        :events="payload.events"
        @close="onClose"
      />

      <div class="drawer-backward-header" v-if="useCloseButton">
        <div class="drawer-backward-header__container">
          <button class="drawer-backward-header__button" touch-effect @click="onClickClose">
            <svg-icon name="close" style="color: #545F71;"/>
          </button>
        </div>
      </div>
    </div>
  </li>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue';
import { computed, nextTick, reactive, ref } from 'vue';
import SvgIcon from '@/components/commons/SvgIcon.vue';
import { useWNInterface } from '@/plugins/vue-wni';
import { DrawerItem, DrawerSize, useDrawerStore } from '@/stores/modules/drawer';
import { useLayoutStore } from '@/stores/modules/layout';

const drawerModule = useDrawerStore();
const layoutStore = useLayoutStore();
const wni = useWNInterface();

const props = defineProps({
  payload: {
    type: Object as PropType<DrawerItem>,
    required: true,
  },
});
const background = ref(null);
const useCloseButton = computed(() => props.payload?.useCloseButton === true);
const isFull = computed(() => props.payload?.size === DrawerSize.FULL);
const isPresentModal = computed(() => props.payload?.useModal !== false);
const state = reactive({
  keyboard_height: computed(() => layoutStore.keyboardHeight),
  styles: computed(() => {
    if (!wni.isAndroid) {
      return {
        'background-color': props.payload.bgColor,
      };
    }

    return {
      '--keyboard-height': `${state.keyboard_height}px`,
      'background-color': props.payload.bgColor,
    };
  }),
});

async function onMouseDown(e) {
  if (props.payload?.canCloseForModal !== false) {
    await nextTick();

    if (e.target == background.value && e.which === 1) {
      drawerModule.dismiss({ id: props.payload.id });
    }
  }
}

async function onClickClose(e) {
  await nextTick();

  drawerModule.dismiss({ id: props.payload.id });
}

async function onClose(e) {
  drawerModule.dismiss({ id: props.payload.id });
}
</script>

<style lang="scss" scoped>
.drawer-item {
  z-index: 1600;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  & > .container {
    @include overflow-hidden;

    padding-top: unit(20);
    width: 100%;
    max-width: $max-content-width;
    margin: auto auto 0 auto;
    min-height: unit(120);
    max-height: calc(100vh - #{unit(78)});
    /* background-color: $color-white; */
    // padding: unit(16);
    padding-bottom: env(safe-area-inset-bottom);
    box-shadow: unit(0) unit(1) unit(5) #a9afb3;
    border-radius: unit(24) unit(24) 0px 0px;
    z-index: 1;
    position: relative;

    .contents {
      height: 100%;
      overflow: auto;
      overscroll-behavior: contain;

      &::after {
        content: '';
        display: block;
        height: var(--keyboard-height);
      }
    }
  }

  &.modal {
    @include fsf;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    box-sizing: border-box;
    /* &::before {
      content: "";
      @include fsa;
      background-color: $color-shadow;
    } */
  }

  &.full {
    & > .container {
      @include safe-area-inset-top;
      @include safe-area-inset-bottom;

      margin: auto;
      height: 100vh;
      max-width: $max-content-width;
      max-height: 100vh;
      background-color: $color-white;
      box-shadow: 0 0 unit(0.4) $color-alpha-black-40;
      border-radius: unit(0.3);
    }

    .drawer-backward-header {
      &__button {
        color: $color-gray-200;
      }
    }
  }

  .drawer-backward-header {
    position: absolute;
    right: unit(16);
    top: unit(16);
    z-index: 2;

    &__container {
      display: flex;
    }

    &__button {
      font-size: unit(24);
      color: $color-gray-200;
    }
  }
}
</style>
