import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "toast-container" }

import { computed } from 'vue';
import { useToastStore } from '@/stores/modules/toast';
import ToastItem from './ToastItem.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Toast',
  setup(__props) {

const toastModule = useToastStore();

const items = computed(() => toastModule.items);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "#teleport-area" }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_TransitionGroup, {
        tag: "ul",
        name: "toast"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.value, (item) => {
            return (_openBlock(), _createBlock(ToastItem, {
              key: item.id,
              data: item
            }, null, 8, ["data"]))
          }), 128))
        ]),
        _: 1
      })
    ])
  ]))
}
}

})