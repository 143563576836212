<template>
    <teleport to="#teleport-area">
      <div ref="alertWrapperEl" class="alert-wrapper" v-if="isOpen">
        <div class="alert">
          <button v-if="props.useCloseButton" class="btn-close" @click="onClose">
            <svg-icon name="close" />
          </button>
          <slot name="content">
            <h2 class="title" v-if="title">
              {{ $t(title) }}
            </h2>
            <p v-if="message">
              {{ $t(message) }}
            </p>
          </slot>
  
          <div class="alert-actions">
            <slot>
              <button type="button" class="button primary" @click="onComplete">
                {{ $t('확인') }}
              </button>
            </slot>
          </div>
        </div>
      </div>
    </teleport>
  </template>
  
  <script lang="ts" setup>
  import { onBeforeMount, onMounted, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import SvgIcon from '@/components/commons/SvgIcon.vue';
  import { useAnalytics } from '@/plugins/analytics';
  import { $t } from '@/plugins/locale';
  
  const analytics = useAnalytics();
  const route = useRoute();
  
  const props = defineProps({
    isOpen: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    useCloseButton: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    hideCancelButton: {
      type: Boolean,
      default: false
    },
  });
  const emits = defineEmits(['close', 'complete']);
  
  const alertWrapperEl = ref(null);
  
  onMounted(() => {
    document.addEventListener('click', handleOutSideClick);
  });
  
  onBeforeMount(() => {
    document.removeEventListener('click', handleOutSideClick);
  });
  
  function onClose() {
    emits('close');
  }
  
  function onComplete() {
    if (props.name === 'login') {
      analytics.event('select_login_alert_button', {
        common: {
          source: `${route.meta.title} 페이지 ${props.label}`,
        },
      });
    }
  
    emits('complete');
  }
  
  function handleOutSideClick(e) {
    if (e.target === alertWrapperEl.value) {
      onClose();
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .alert-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $modal-index;
  
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
    }
  
    .alert {
      @include fix-center;
      padding: unit(29.5) unit(20) unit(17);
      width: 86%;
      min-width: unit(306);
      max-width: unit(311);
      background-color: $color-background-page;
      border-radius: unit(16);
  
      .title {
        @include text-variant(headline2);
        text-align: center;
  
        & + p {
          margin-top: unit(12);
        }
      }
  
      p {
        @include text-variant(body1, $color-gray-120);
        text-align: center;
        white-space: pre-line;
      }
  
      &-actions {
        display: flex;
        justify-content: space-around;
        gap: unit(10);
        margin-top: unit(24);
  
        .button {
          @include text-variant(button1);
          width: 50%;
          padding: unit(12);
          border-radius: unit(12);
          border: 1px solid $color-divider-line;
  
          &.primary {
            border: 0;
            background-color: $color-primary;
            color: $color-white;
          }
        }
      }
    }
  }
  
  :deep(.button) {
    @include text-variant(button2);
    width: 50%;
    padding: unit(12);
    border-radius: unit(12);
    border: 1px solid $color-divider-line;
    font-weight: 500;
    text-align: center;
  
    &.primary {
      border: 0;
      background-color: $color-primary;
      color: $color-white;
      font-weight: 700;
  
      &:disabled,
      &.disabled {
        background-color: $color-gray-224;
      }
    }
  
    &.outlined {
      background-color: $color-white;
  
      &.primary {
        border: 1px solid $color-primary;
        color: $color-primary;
        font-weight: 700;
      }
    }
  
    &.full-width {
      width: 100%;
    }
  }
  
  .btn-close {
    position: absolute;
    right: 0;
    top: unit(-46);
    font-size: unit(40);
    color: $color-gray-224;
  }
  </style>
  