import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

import { computed, reactive, watch } from 'vue';
import { useRoute } from 'vue-router';
import SpaceRegisterBanner from '@/components/resources/space/SpaceRegisterBanner.vue';
import { useAnalytics } from '@/plugins/analytics';
import { useWNInterface } from '@/plugins/vue-wni';
import { useDrawerStore } from '@/stores/modules/drawer';
import { useLayoutStore } from '@/stores/modules/layout';
import { useSessionStore } from '@/stores/modules/session';
import { useSpaceStore } from '@/stores/modules/space';


export default /*@__PURE__*/_defineComponent({
  __name: 'Layout',
  setup(__props) {

const route = useRoute();
const drawerModule = useDrawerStore();
const layoutStore = useLayoutStore();
const spaceStore = useSpaceStore();
const wni = useWNInterface();
const analytics = useAnalytics();
const sessionStore = useSessionStore();
const showKeyboard = computed(() => (state.keyboard_height > 0 ? 'keyboard-show' : ''));

const draftSpace = computed(() => spaceStore.getCached('draft_space'));
const state = reactive({
  isDrawer: computed(() => !drawerModule.items.length),
  keyboard_height: computed(() => layoutStore.keyboardHeight),
  styles: computed(() => {
    if (!wni.isAndroid) {
      return;
    }

    if (state.keyboard_height > 0 && state.isDrawer) {
      return { '--keyboard-height': `${state.keyboard_height}px` };
    }
    return {};
  }),
  isSpaceRegisterBannerClose: computed(() => sessionStore.isSpaceRegisterBannerClose),
  showSpaceRegisterBanner: computed(() => {
    if (draftSpace.value && (route.name === 'Home' || route.name === 'Mypage') && !state.isSpaceRegisterBannerClose) {
      analytics.event('view_register_space_continue_banner');
      return true;
    }

    return false;
  }),
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['page', _unref(route).name, showKeyboard.value]),
    style: _normalizeStyle(state.styles)
  }, [
    (state.showSpaceRegisterBanner)
      ? (_openBlock(), _createBlock(SpaceRegisterBanner, {
          key: 0,
          progress: state.progress,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_unref(sessionStore).setIsSpaceRegisterBannerClose(true)))
        }, null, 8, ["progress"]))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_component_router_view, {
      key: _ctx.$route.fullPath
    }, {
      default: _withCtx(({ Component }) => [
        (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
      ]),
      _: 1
    }))
  ], 6))
}
}

})