import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "alert-banner" }
const _hoisted_2 = { class: "message-wrapper" }
const _hoisted_3 = ["innerHTML"]

import { computed, ref } from 'vue';
import SvgIcon from '@/components/commons/SvgIcon.vue';
import ProgressBar from '@/components/commons/utils/ProgressBar.vue';
import { useAnalytics } from '@/plugins/analytics';
import { $t } from '@/plugins/locale';
import { useSpaceStore } from '@/stores/modules/space';


export default /*@__PURE__*/_defineComponent({
  __name: 'SpaceRegisterBanner',
  emits: ['close'],
  setup(__props, { emit: __emit }) {

const analytics = useAnalytics();
const spaceStore = useSpaceStore();

const emit = __emit;
const progress = computed(() => {
  const draftSpace = spaceStore.getCached('draft_space');
  if (!draftSpace) {
    return 0;
  }

  const { extras } = draftSpace;
  return extras?.draft_progress;
});

function onClickAlertBannerClose() {
  analytics.event('select_register_space_continue_close_button');
  emit('close');
}

function onClickLink() {
  analytics.event('select_register_space_continue_banner');
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: { name: 'IntroSpaceRegister' },
      onClick: onClickLink
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(SvgIcon, { name: "sign-caution" }),
          _createElementVNode("div", null, [
            _createElementVNode("p", {
              innerHTML: _unref($t)('공간 등록을 하지 않으면 <span>매 월 최소 11만 원</span>을 놓쳐요. 서둘러 공간을 등록해 주세요')
            }, null, 8, _hoisted_3),
            _createVNode(ProgressBar, { progress: progress.value }, null, 8, ["progress"])
          ])
        ])
      ]),
      _: 1
    }),
    _createElementVNode("button", {
      type: "button",
      onClick: onClickAlertBannerClose
    }, [
      _createVNode(SvgIcon, { name: "close" })
    ])
  ]))
}
}

})