import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment } from "vue"

import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { useLayoutStore } from '@/stores/modules/layout';
import { useToastStore } from '@/stores/modules/toast';
import { createLogger } from '@/utils/logger';


export default /*@__PURE__*/_defineComponent({
  __name: 'PageActions',
  props: {
  float: {
    type: Boolean,
    default: false,
  },
  class: {
    type: String,
    default: null,
  },
  isBubble: {
    type: Boolean,
    default: false,
  },
  bubbleMessage: {
    type: String,
    default: '',
  },
},
  setup(__props) {

const layout = useLayoutStore();
const toastModule = useToastStore();
const debug = createLogger('app:components:resources:layout:PageActions');
const props = __props;
const classNames = computed(() => [props.float && 'floating', props.class ? props.class : '']);
const screen = ref(null);
const isKeyboardVisible = ref(false);
const refPageActions = ref(null);
const isBubbleShow = ref(props.isBubble);
const actionsHeight = ref(0);

const resizeActionHide = () => {
  layout.resize({});

  screen.value = {
    screenHeight: window.screen.height,
    availHeight: window.screen.availHeight,
    innerHeight: window.innerHeight,
    clientHeight: document.body.clientHeight,
    webviewHeight: window.visualViewport.height,
    bodyHeight: document.body.offsetHeight,
    testHeight: window.screen.availHeight * (document.body.clientHeight / window.screen.availHeight),
  };

  debug(
    'resize',
    screen.value.screenHeight * 0.7,
    screen.value.webviewHeight,
    screen.value.screenHeight * 0.7 < screen.value.testHeight,
  );

  if (screen.value.screenHeight * 0.7 < screen.value.testHeight) {
    isKeyboardVisible.value = false;
  } else {
    isKeyboardVisible.value = true;
  }
};
onMounted(() => {
  actionsHeight.value = refPageActions.value.clientHeight;
  toastModule.setMargin({ bottom: actionsHeight.value });
});
onBeforeUnmount(() => {
  toastModule.setMargin(null);
});

function onClickBubble() {
  isBubbleShow.value = false;
}

return (_ctx: any,_cache: any) => {
  const _component_resize_observer = _resolveComponent("resize-observer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(["page-actions", classNames.value]),
      style: _normalizeStyle({ '--actions-height': `${actionsHeight.value}px` })
    }, [
      (__props.isBubble)
        ? _withDirectives((_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "speech-bubble",
            onClick: onClickBubble
          }, [
            _createElementVNode("p", null, _toDisplayString(__props.bubbleMessage), 1)
          ], 512)), [
            [_vShow, isBubbleShow.value]
          ])
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        ref_key: "refPageActions",
        ref: refPageActions,
        class: "page-actions-content"
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 512)
    ], 6), [
      [_vShow, !isKeyboardVisible.value]
    ]),
    _createVNode(_component_resize_observer, { onNotify: resizeActionHide })
  ], 64))
}
}

})