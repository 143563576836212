import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "resource-content page-content" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "message" }

import { ref } from 'vue';
import PageActions from '@/components/commons/layout/PageActions.vue';
import { $t } from '@/plugins/locale';


export default /*@__PURE__*/_defineComponent({
  __name: 'LaunchBanned',
  setup(__props) {

const displayName = ref('아무개아무개아');

function onClickContact(e) {}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", {
          innerHTML: _unref($t)('<em>{displayName}</em>님은<br/>해당 서비스 사용이 중지되었어요', { displayName: displayName.value })
        }, null, 8, _hoisted_3)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("ul", null, [
          _createElementVNode("li", null, [
            _createElementVNode("p", null, _toDisplayString(_unref($t)('해당 서비스는 이용자가 정책을 준수하지 않은 경우 사용 중지됩니다.')), 1)
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("p", null, _toDisplayString(_unref($t)('해당 계정 복원 요청을 위해서는 1:1 문의하기를 이용해 주세요')), 1)
          ])
        ])
      ])
    ]),
    _createVNode(PageActions, null, {
      default: _withCtx(() => [
        _createElementVNode("button", {
          type: "button",
          class: "btn-large btn-filled",
          onClick: onClickContact
        }, [
          _createElementVNode("span", null, _toDisplayString(_unref($t)('1:1 문의하기')), 1)
        ])
      ]),
      _: 1
    })
  ], 64))
}
}

})