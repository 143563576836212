import axios from 'axios';
import config from '@/config';

axios.defaults.baseURL = config.apiServer;
axios.interceptors.request.use(
  function (config) {
    const { pathname = '', search = '' } = window.location;
    config.headers['page-location'] = pathname + search;
    config.headers['Access-Control-Allow-Origin'] = '*';

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export function setAccessToken(accessToken: string | null) {
  if (accessToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
}

/**
 * 디바이스 정보를 HTTP 헤더에 설정하는 함수
 *
 * @description
 * - 디바이스 정보를 'x-device-info' 헤더에 JSON 문자열로 설정
 * - deviceInfo가 null이면 헤더에서 제거
 * - API 요청 시 디바이스 정보를 서버에 전달하기 위해 사용
 *
 * @param deviceInfo 디바이스 정보 객체 또는 null
 */
export function setDeviceInfo(deviceInfo: any) {
  if (deviceInfo) {
    // axios.defaults.headers.common에 값을 지정해두면, 모든 요청에 대해 공통으로 자동으로 포함되는 헤더가 됩니다.
    axios.defaults.headers.common['x-device-info'] = JSON.stringify(deviceInfo);
  } else {
    delete axios.defaults.headers.common['x-device-info'];
  }
}

export const sdkAxios = axios.create({
  baseURL: config.apiServer,
});

sdkAxios.interceptors.request.use((config) => {
  config.baseURL;
  config.headers['Access-Control-Allow-Origin'] = '*';

  return config;
});

export function setSDKAccessToken(accessToken: string | null) {
  if (accessToken) {
    sdkAxios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    delete sdkAxios.defaults.headers.common.Authorization;
  }
}

export default axios;
