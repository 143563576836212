import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "alert" }
const _hoisted_2 = {
  key: 0,
  class: "title"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "alert-actions" }

import { onBeforeMount, onMounted, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import SvgIcon from '@/components/commons/SvgIcon.vue';
  import { useAnalytics } from '@/plugins/analytics';
  import { $t } from '@/plugins/locale';
  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'AppAlert',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    useCloseButton: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    hideCancelButton: {
      type: Boolean,
      default: false
    },
  },
  emits: ['close', 'complete'],
  setup(__props, { emit: __emit }) {

  const analytics = useAnalytics();
  const route = useRoute();
  
  const props = __props;
  const emits = __emit;
  
  const alertWrapperEl = ref(null);
  
  onMounted(() => {
    document.addEventListener('click', handleOutSideClick);
  });
  
  onBeforeMount(() => {
    document.removeEventListener('click', handleOutSideClick);
  });
  
  function onClose() {
    emits('close');
  }
  
  function onComplete() {
    if (props.name === 'login') {
      analytics.event('select_login_alert_button', {
        common: {
          source: `${route.meta.title} 페이지 ${props.label}`,
        },
      });
    }
  
    emits('complete');
  }
  
  function handleOutSideClick(e) {
    if (e.target === alertWrapperEl.value) {
      onClose();
    }
  }
  
return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "#teleport-area" }, [
    (__props.isOpen)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          ref_key: "alertWrapperEl",
          ref: alertWrapperEl,
          class: "alert-wrapper"
        }, [
          _createElementVNode("div", _hoisted_1, [
            (props.useCloseButton)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "btn-close",
                  onClick: onClose
                }, [
                  _createVNode(SvgIcon, { name: "close" })
                ]))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "content", {}, () => [
              (__props.title)
                ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_unref($t)(__props.title)), 1))
                : _createCommentVNode("", true),
              (__props.message)
                ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_unref($t)(__props.message)), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "default", {}, () => [
                _createElementVNode("button", {
                  type: "button",
                  class: "button primary",
                  onClick: onComplete
                }, _toDisplayString(_unref($t)('확인')), 1)
              ])
            ])
          ])
        ], 512))
      : _createCommentVNode("", true)
  ]))
}
}

})