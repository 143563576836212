import { defineComponent as _defineComponent } from 'vue'
import { vShow as _vShow, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "animation lottie" }
const _hoisted_2 = { class: "lottie-still" }

import lottie from 'lottie-web';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Lottie',
  props: {
  animationData: {
    type: Object,
    required: true,
  },
  loop: {
    type: Boolean,
    default: true,
  },
  autoplay: {
    type: Boolean,
    default: true,
  },
  animating: {
    type: Boolean,
    default: true,
  },
  width: {
    type: [Number, String],
    default: '',
  },
  height: {
    type: [Number, String],
    default: '',
  },
},
  setup(__props) {

const props = __props;

const lottieAnimation = ref(null);
const animation = ref(null);

const style = computed(() => {
  return {
    width: props.width ? `${props.width}px` : '100%',
    height: props.height ? `${props.height}px` : '100%',
    // overflow: "hidden",
    // margin: "0 auto",
  };
});

// const rendererSettings = computed(() => {
//   let canvasContext = refs.canvas.getContext('2d');
//   return {
//     context: canvasContext, // the canvas context
//     // preserveAspectRatio: 'xMinYMin slice', // Supports the same options as the svg element's preserveAspectRatio property
//     clearCanvas: false,
//     progressiveLoad: true, // Boolean, only svg renderer, loads dom elements when needed. Might speed up initialization for large number of elements.
//     hideOnTransparent: true, //Boolean, only svg renderer, hides elements when opacity reaches 0 (defaults to true)
//   };
// });

onMounted(() => {
  lottieAnimation.value = lottie.loadAnimation({
    name: `lottie-${+new Date()}`,
    container: animation.value,
    renderer: 'svg',
    loop: props.loop !== false,
    autoplay: props.autoplay !== false,
    animationData: props.animationData,
    // rendererSettings: this.rendererSettings
  });

  lottieAnimation.value.addEventListener('complete', onComplete);
});

onBeforeUnmount(() => {
  if (lottieAnimation.value) {
    lottieAnimation.value.removeEventListener('complete', onComplete);

    lottieAnimation.value.stop();
    lottieAnimation.value.destroy();
    lottieAnimation.value = null;
  }
});

function onComplete() {}

function onSpeed(value = 1) {
  lottieAnimation.value.setSpeed(value);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", {
      ref_key: "animation",
      ref: animation,
      class: "lottie-container",
      style: _normalizeStyle(style.value)
    }, null, 4), [
      [_vShow, __props.animating]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "still", { ref: "still" })
    ], 512), [
      [_vShow, !__props.animating]
    ])
  ]))
}
}

})