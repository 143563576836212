import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, unref as _unref, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "version"
}

import { computed, onBeforeMount, onMounted, provide, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import UiDrawer from '@/components/commons/drawer/Drawer.vue';
import UiToast from '@/components/commons/toast/Toast.vue';
import PageLaunch from '@/pages/common/launch/PageLaunch.vue';
import { useWNInterface } from '@/plugins/vue-wni';
import { APP_STATUS, useAppStore } from '@/stores/modules/app';
import { useDrawerStore } from '@/stores/modules/drawer';
import { useLayoutStore } from '@/stores/modules/layout';
import { useSessionStore } from '@/stores/modules/session';
import { useToastStore } from '@/stores/modules/toast';
import Alert from '@/components/commons/modal/AppAlert.vue';
import logger from '@/utils/logger';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const debug = logger('app:main');
debug.enabled = true;

const router = useRouter();
const route = useRoute();
const wni = useWNInterface();
const drawerModule = useDrawerStore();

const app = useAppStore();
const session = useSessionStore();
const layout = useLayoutStore();

const state = reactive({
  isReady: false,
  error: null,
});
const androidExit = reactive({
  is_exit: false,
  timeout: null,
});

const canUseRouter = computed((value) => {
  if (!state.isReady) {
    return false;
  }

  return appStatus.value === APP_STATUS.LAUNCHED || appStatus.value === APP_STATUS.EXTERNAL;
});

const mode = computed(() => app.config.mode);

const isModeShow = computed(() => (mode.value !== 'production' ? true : false));

const classNames = computed(() => [
  layoutMode.value !== 'unset' && layoutMode.value,
  isDarkMode.value && 'dark',
  mode.value !== 'production' && mode.value,
]);

const appStatus = computed(() => app.status);

const appAppearance = computed(() => (!state.isReady ? 'unset' : app.currentAppearance));

const isDarkMode = computed(() => {
  if (!state.isReady) {
    return false;
  }
  return appAppearance.value === 'dark' ? true : false;
});

const webVersion = computed(() => app.config.version);

const layoutMode = computed(() => layout.layoutMode);

watch(appStatus, (value) => {
  debug('appStatus', value);
});

onBeforeMount(async () => {
  try {
    app.setStatus(APP_STATUS.LAUNCHING);

    if (wni.isNative) {
      await initWNInterface();
    }
    await initializeStores();

    state.isReady = true;
  } catch (e) {
    app.setStatus(APP_STATUS.ERROR);
  }
});

onMounted(async () => {
  if (wni.isNative) {
    await onDynamicLink();
  }
});

async function initializeStores() {
  await app.init();
  await session.init();
  await layout.init();
}

function initWNInterface() {
  return new Promise((resolve, reject) => {
    debug('initWNInterface');
    wni.onReady((e) => {
      wni.onAppear((e) => {
        onDarkMode();
        onSetBadgeNumber();
      });

      wni.onBack(() => {
        if (wni.isAndroid) {
          if (drawerModule.items.length > 0) {
            drawerModule.dismissAll();
            return;
          }

          if (!history.state.back && route.name !== 'Home') {
            if (route.name === 'MyBookDetailTo') {
              return router.replace({ name: 'MySpaceManage', params: { id: route.query.space_id } });
            } else if (route.name === 'MySpaceManage') {
              router.replace({ name: 'MySpaces' });
            } else if (route.name === 'MySpaces') {
              router.replace({ name: 'Mypage' });
            } else if (route.name === 'MyBookDetailFrom') {
              router.replace({ name: 'MyBookFrom' });
            } else if (route.name === 'MyBookFrom') {
              router.replace({ name: 'Mypage' });
            } else if (route.name === 'Chat') {
              router.replace({ name: 'ChatList' });
            } else {
              router.replace({ name: 'Home' });
            }

            return;
          }

          if (!history.state.back && route.name === 'Home') {
            tryToExit();
            return;
          }

          router.back();
        }
      });

      wni.onKeyboard((data) => {
        if (data.isVisibility === true) {
          useLayoutStore().setKeyboardHeight(data.height);
        } else {
          useLayoutStore().setKeyboardHeight(0);
        }
      });

      resolve(e);
    });
  });
}

function tryToExit() {
  if (androidExit.is_exit) {
    wni.execute('wnAppClose', {});
    androidExit.is_exit = false;
    return;
  }

  androidExit.is_exit = true;
  androidExit.timeout = setTimeout(() => {
    androidExit.timeout = null;
    androidExit.is_exit = false;
  }, 2000);

  useToastStore().alert({ message: '유후를 종료하려면 다시 탭하세요' });
}

function onDynamicLink() {
  wni.execute('wnStartLinking', {});
  wni.onLink(async (e) => {
    if (!e) {
      return;
    }

    try {
      const { link } = e;
      const url = new URL(link);
      const { pathname, search } = url;
      if (pathname === '/space') {
        const id = url.searchParams.get('space_id');
        await router.replace({ name: 'SpaceDetail', params: { id } });
        return;
      }

      await router.replace(pathname);
      return;
    } catch (err) {
      console.log('onLink err : ', err);
    } finally {
    }
  });
}

function onSetBadgeNumber(number = 0) {
  debug('onSetBadgeNumber', number);

  wni.execute('wnPushBadgeNumber', {
    number,
  });
}

function onResize() {
  debug('onResize');

  layout.resize({});
}

async function onInitialize(e) {
  debug('onInitialize', e);
}

function onDarkMode() {
  debug('onDarkMode');
}

// app global alert
const alertOpen = ref(false);
const alertMessage = ref('');
const alertCallback = ref(null);
const openAlert = (message, callback) => {
  alertMessage.value = message;
  alertCallback.value = callback || (() => {});
  alertOpen.value = true;
};
const closeAlert = () => {
  alertOpen.value = false;
  if (alertCallback) alertCallback.value();
};
provide('open_alert', openAlert);

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_resize_observer = _resolveComponent("resize-observer")!

  return (canUseRouter.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref: "refApp",
        class: _normalizeClass(["app-container", classNames.value])
      }, [
        (isModeShow.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
              _createTextVNode(_toDisplayString(mode.value) + " ", 1),
              (_unref(wni).isNative)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_unref(app)?.deviceInfo?.app_version), 1)
                  ], 64))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(webVersion.value), 1)
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_router_view),
        _createVNode(UiToast),
        _createVNode(UiDrawer),
        _createVNode(Alert, {
          name: "appalert",
          message: alertMessage.value,
          "is-open": alertOpen.value,
          "hide-cancel-button": true,
          onComplete: closeAlert
        }, null, 8, ["message", "is-open"]),
        _createVNode(_component_resize_observer, { onNotify: onResize })
      ], 2))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(["app-container", classNames.value])
      }, [
        _createVNode(PageLaunch, {
          status: appStatus.value,
          onInitialize: onInitialize
        }, null, 8, ["status"])
      ], 2))
}
}

})