import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotionDecorator = _resolveComponent("NotionDecorator")!

  return (_openBlock(), _createElementBlock("span", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.text, (t, i) => {
      return (_openBlock(), _createBlock(_component_NotionDecorator, _mergeProps({
        key: i,
        content: t,
        ref_for: true
      }, _ctx.pass), null, 16, ["content"]))
    }), 128))
  ]))
}