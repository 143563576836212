<script setup>
import { inject, ref } from 'vue';
import { apifetch } from '../../apifetch';
import { SpaceApi } from '@/api/SpaceApi';
import { basicErrorHandler } from '../../api-error-handler';

const openAlert = inject('open_alert');

function handleApiGet() {
  apifetch('/test')
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
    })
    .catch((error) => basicErrorHandler(error, openAlert));
}

async function handleOldApiError() {
  const fakeId = 100;
  const fakeSpace = {};

  const api = new SpaceApi();
  try {
    const { data } = await api.spaceControllerUpdate(fakeId, fakeSpace);
  } catch (error) {
    alert(error.message);
  }

  return data;
}

function handleApi404() {
  openAlert('hi', () => {
    console.log('callback');
  });
}

function handleNewApiError() {
  const fakeId = 100;
  const fakeSpace = {};

  apifetch(`/spaces/${fakeId}`, {
    method: 'put',
    body: JSON.stringify(fakeSpace),
  })
    .then((response) => response.json())
    .then((data) => {})
    .catch((error) => basicErrorHandler(error, openAlert));
}

console.log(process.env);
</script>

<template>
  <div style="display: flex; flex-direction: column; padding-top: 100px">
    <button style="margin-bottom: 40px" @click="handleApiGet">api get</button>
    <button style="margin-bottom: 40px" @click="handleApi404">api 404</button>
    <button style="margin-bottom: 40px" @click="handleOldApiError">old api, 500</button>
    <button style="margin-bottom: 40px" @click="handleNewApiError">new api, 500</button>
  </div>
</template>

<style scoped>
button {
  font-weight: bold;
}
</style>
