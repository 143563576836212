import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'SvgIcon',
  props: {
  name: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const props = __props;
const iconName = `#icon-${props.name}`;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass(["icon svg-icon", __props.name]),
    "aria-hidden": "true"
  }, [
    _createElementVNode("use", { "xlink:href": iconName })
  ], 2))
}
}

})