import { StorageModule } from '@/utils/storage';

const storage = new StorageModule();
const BaseApiUrl = `${process.env.VUE_APP_API_SERVER}/v1`;

export async function apifetch(url, options, contentType = 'application/json') {
  try {
    const headers = {
      'Content-Type': contentType,
      // 'Os': Platform.OS,
      // 'Appversion': env.version,
    };
    const token = await storage.get('__SESSION_TOKEN__');
    if (token) headers['Authorization'] = `Bearer ${token}`;

    const response = await fetch(BaseApiUrl + url, {
      mode: 'cors',
      headers,
      ...options,
    });

    if (response.status === 401) {
      const error = new Error();
      error.code = 'need_authenticate';
      throw error;
    }

    if (response.status === 403) {
      const error = new Error();
      error.code = 'unauthorized';
      throw error;
    }

    if (response.status === 404) {
      const error = new Error();
      error.code = '404';
      throw error;
    }

    if (response.status === 500) {
      const data = await response.json();
      if (data.message === 'INSUFFICIENT_PARAMS') {
        console.error(data);
      }
      throw new Error(data.message || '통신실패');
    }

    if (!response.ok) {
      throw new Error(`Response status: ${response.status}`);
    }

    // const result = await response.text();
    // console.log('result text:', result);

    return response;
  } catch (error) {
    console.error('fetch error:', error);
    throw error;
  }
}
