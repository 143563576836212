import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { computed, onMounted, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import LaunchBanned from '@/components/resources/launch/LaunchBanned.vue';
import LaunchError from '@/components/resources/launch/LaunchError.vue';
import LaunchLoading from '@/components/resources/launch/LaunchLoading.vue';
import LaunchNeedToUpdate from '@/components/resources/launch/LaunchNeedToUpdate.vue';
import LaunchVisitFirst from '@/components/resources/launch/LaunchVisitFirst.vue';
import { APP_STATUS, useAppStore } from '@/stores/modules/app';
import { useSessionStore } from '@/stores/modules/session';
import { useSpaceStore } from '@/stores/modules/space';
import logger from '@/utils/logger';


export default /*@__PURE__*/_defineComponent({
  __name: 'PageLaunch',
  props: {
  status: {
    type: String,
    required: true,
  },
  data: {
    type: Object,
    default: () => ({}),
  },
},
  emits: ['initialize', 'error'],
  setup(__props, { emit: __emit }) {

const debug = logger('app:pages:launch');

const route = useRoute();
const router = useRouter();
const app = useAppStore();
const session = useSessionStore();
const spaceStore = useSpaceStore();

const emit = __emit;

const props = __props;

const state = reactive({
  is_ready: false,
  error: null,
});
const launchStatus = computed(() => {
  const value = props.status || route.params.status.toString().toUpperCase() || 'UNSET';
  return value;
});

onMounted(() => {
  onReady();
});

async function onReady() {
  if (state.is_ready) {
    return;
  }
  try {
    state.is_ready = true;

    await initializeApp();
    await initializeSessions();

    if (session.isLogged) {
      const agrees: any = await session.checkAgreeToTerms();
      if (agrees.count > 0) {
        router.push({ name: 'AgreeToPolicy' });
      }

      if (session.sdkProfile.space_draft_id) {
        const draftSpace = await spaceStore.fetchSpace(session.sdkProfile.space_draft_id);
        spaceStore.setCached('draft_space', draftSpace);
      }
    }

    if (!app.isFirstOpened && (route.path.includes('biz') || route.path.includes('policy'))) {
      app.setStatus(APP_STATUS.EXTERNAL);
    } else {
      await app.start();
    }
  } catch (e) {
    const isNetworkError = e.message === 'Network Error';

    state.error = e;
    app.status = APP_STATUS.ERROR;
  } finally {
    emit('initialize', session);
  }
}

async function initializeApp() {
  debug('initializeApp');

  try {
    if (!app.initialized) {
      await app.loadDeviceInfo({});
      await app.initialize({});
      await app.pushRegister({});
    }
  } catch (e) {
    console.error(e);
    throw e;
  }

  // @TODO: 버전 체크, zkp 적용
}

async function initializeSessions() {
  debug('initializeSessions');
  try {
    await setupSession();
    await setupSDKSession();
  } catch (e) {
    console.error(e);
    await session.unregister();
    await session.unregisterSDK();
    // @TODO toast error 노출 필요\
  }
}

async function setupSession() {
  debug('setupSession');
  try {
    // if (wni.isNative) {
    if (session.accessToken) {
      await session.verify({});
    } else {
      await session.anonymous({});
    }
    // } else {
    //   if (session.accessToken) {
    //     await session.verify({});
    //   } else {
    //     throw new Error('토큰이 없어요');
    //   }
    // }
  } catch (e) {
    throw e;
  }
}

async function setupSDKSession() {
  debug('setupSDKSession');
  try {
    const skdToken = session.sdkToken;

    if (skdToken) {
      try {
        await session.verifySDK();
      } catch (e) {
        await session.authorizeSDK();
      }
    } else {
      await session.authorizeSDK();
    }
  } catch (e) {
    throw e;
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (launchStatus.value === 'UNSET' || launchStatus.value === 'LAUNCHING')
      ? (_openBlock(), _createBlock(LaunchLoading, { key: 0 }))
      : _createCommentVNode("", true),
    (launchStatus.value === 'VISIT_FIRST')
      ? (_openBlock(), _createBlock(LaunchVisitFirst, { key: 1 }))
      : _createCommentVNode("", true),
    (launchStatus.value === 'NEED_APP_UPDATE')
      ? (_openBlock(), _createBlock(LaunchNeedToUpdate, { key: 2 }))
      : _createCommentVNode("", true),
    (launchStatus.value === 'ERROR')
      ? (_openBlock(), _createBlock(LaunchError, {
          key: 3,
          error: state.error
        }, null, 8, ["error"]))
      : _createCommentVNode("", true),
    (launchStatus.value === 'BANNED')
      ? (_openBlock(), _createBlock(LaunchBanned, { key: 4 }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})