import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "drawer-container" }

import { computed, onMounted, reactive, watch } from 'vue';
import { useDrawerStore } from '@/stores/modules/drawer';
import DrawerItem from './DrawerItem.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Drawer',
  props: {
  observerHandler: {
    type: Function,
    default: null,
  },
},
  setup(__props) {

const props = __props;

const drawerModule = useDrawerStore();

onMounted(() => {
  if (props.observerHandler) {
    drawerModule.addObserver(props.observerHandler);
  }
});

watch(
  () => drawerModule.transitionName,
  (newValue, oldValue) => {
    state.transitionName = newValue;
  },
);

const items = computed(() => drawerModule.items);
const state = reactive({
  transitionName: drawerModule.transitionName,
});

function closeDrawer() {
  drawerModule.dismissAll();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "#teleport-area" }, [
    (items.value.length > 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onClick: closeDrawer,
          class: "drawer-bg"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_TransitionGroup, {
        tag: "ul",
        name: state.transitionName
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.value, (item) => {
            return (_openBlock(), _createBlock(DrawerItem, {
              ref_for: true,
              ref: item.ref,
              key: item.id,
              payload: item
            }, null, 8, ["payload"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["name"])
    ])
  ]))
}
}

})