import { cloneDeep, filter } from 'lodash';
import { mappingDistrictMainData } from '@/data/district';
import { spaceFacilityList, spaceSizeList } from '@/data/space';
import { DEFAULT_DAY_PRICE } from '@/components/resources/space/constants/space.constant';

const PYEONG_CONVERSION_CONSTANT = 3.30579;
export function getSpaceSizeLabel(value) {
  const size = spaceSizeList.find((item) => item.value === value);

  if (!size) {
    return `${value}평`;
  }
  return size.label;
}

export function getSpaceFacilityList(filterFn: Function) {
  return filter(cloneDeep(spaceFacilityList), filterFn);
}

/**
 *
 * 제곱미터, 평 환산법
 * 제곱미터 * 0.3025 = 평
 * 평 * 3.3058 = 제곱미터
 */
// 제곱미터 -> 평수 변환
export function convertSqmToPy(sqm): number {
  const py = sqm * 0.3025;
  return py;
}

export function convertPyToSqm(py): number {
  const sqm = Number(py) * 3.3058;
  return Math.round(sqm * 10) / 10;
}

export const calcFloorSize = (width: number, height: number) => {
  if (!width && !height) {
    return null;
  }

  const w = width / 100;
  const h = height / 100;
  const squareM = w * h;
  let squareKr = convertSqmToPy(squareM);

  /**
   * (정책 v1)
   * - 소수점 이하 둘째자리 버림 (ex, 3.14 -> 3.1)
   */
  if (squareKr && Math.floor(squareKr * 10) / 10 === 0) {
    squareKr = 0.1;
  }

  return {
    sqm: Math.round(squareM * 10) / 10,
    py: Math.round(squareKr * 10) / 10,
  };
};

export const convertCmToM = (cm) => {
  return cm / 100 + 'm' || null;
};

// 공간 크기에 따라 주 단위 예상이용료
export const calcPriceBySpaceSize = (
  width: number,
  height: number,
  dayPrice?: number,
): { totalPrice; basePrice; serviceFee; unitPrice } => {
  // 1평당 주 이용가격 (기본값: 25,000원)
  const UNIT_PRICE = dayPrice ?? DEFAULT_DAY_PRICE;

  // 서비스 수수료 비율 (15%)
  const SERVICE_FEE_PERCENT = 0.15;

  // 공간의 평수 계산 (calcFloorSize 함수를 통해 제곱미터를 평으로 변환)
  const size = calcFloorSize(width, height)?.py;

  // 기본 가격 계산 (평수 * 평당 가격)
  const basePrice = size * UNIT_PRICE;

  // 매칭 수수료 계산 (기본 가격 * 수수료 비율)
  const serviceFee = basePrice * SERVICE_FEE_PERCENT;

  // 총 가격 계산 (기본 가격 + 서비스 수수료)
  const totalPrice = basePrice + serviceFee;

  // 계산된 가격 정보 반환
  return {
    totalPrice, // 총 가격 (기본 가격 + 서비스 수수료)
    basePrice, // 기본 가격 (평수 * 평당 가격)
    unitPrice: UNIT_PRICE,
    // unitPrice: size > 1 ? UNIT_PRICE : basePrice, // 1평 초과시 평당 가격, 1평 이하시 기본 가격
    serviceFee, // 서비스 수수료
  };
};

export const mappingDistrictMain = (input) => {
  const output = mappingDistrictMainData[input];

  return output ? output : '';
};
