import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "notion-simple-table-row" }
const _hoisted_2 = { class: "notion-simple-table-cell-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotionTextRenderer = _resolveComponent("NotionTextRenderer")!

  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.columns, (columnId, columnIndex) => {
      return (_openBlock(), _createElementBlock("td", {
        key: columnIndex,
        class: "notion-simple-table-data"
      }, [
        _createElementVNode("div", {
          class: _normalizeClass({ 'notion-simple-table-header': $options.isHeader(columnIndex) })
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_NotionTextRenderer, _mergeProps({
              text: $options.cell(columnId),
              ref_for: true
            }, _ctx.pass), null, 16, ["text"])
          ])
        ], 2)
      ]))
    }), 128))
  ]))
}