import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "toast-body" }

import type { PropType } from 'vue';
import { computed, onMounted, ref } from 'vue';
import SvgIcon from '@/components/commons/SvgIcon.vue';
import { $t } from '@/plugins/locale';
import { useLayoutStore } from '@/stores/modules/layout';
import { ToastItem, ToastType, useToastStore } from '@/stores/modules/toast';


export default /*@__PURE__*/_defineComponent({
  __name: 'ToastItem',
  props: {
  data: {
    type: Object as PropType<ToastItem>,
    default: () => {},
  },
},
  setup(__props) {

const toastModule = useToastStore();
const layout = useLayoutStore();

const props = __props;

const type = ref((props.data.type ?? ToastType.ALERT).toLowerCase());
const message = computed(() => {
  const e = props.data.message as any;

  if (e) {
    if (typeof e === 'string') {
      return e;
    } else if (e.data && e.data.error) {
      return e.data.error;
    } else if (e.error_msg) {
      return e.error_msg;
    } else if (e.details) {
      return e.details;
    } else if (typeof e === 'object') {
      return e.toString();
    }
  }

  return 'Unknown Message';
});
const itemPosition = computed(() => {
  if (toastModule?.margin === null || toastModule?.margin === undefined) {
    return {};
  }

  const margin = {};
  for (const key in toastModule.margin) {
    margin[key] = `${layout.unit(toastModule.margin[key])}px`;
  }

  return margin;
});

// 1. Mount 후 자동 삭제 설정
onMounted(() => {
  if (props.data.duration) {
    setTimeout(removeItem, props.data?.duration ?? 3500);
  }
});

// 2. Toast 제거 함수
function removeItem() {
  toastModule.hide(props.data);
}

// 3. "보러가기" 버튼 클릭 핸들러
function handleButtonClick() {
  if (props.data.button && props.data.button.onClick) {
    props.data.button.onClick(); // 프롭으로 전달된 클릭 핸들러 실행
  }
  removeItem(); // 버튼 클릭 후 Toast 제거
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(["toast-item", type.value]),
    style: _normalizeStyle({ ...itemPosition.value })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(SvgIcon, { name: "info-bang" }),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_unref($t)(message.value)), 1),
      (props.data.button)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "close",
            onClick: handleButtonClick
          }, _toDisplayString(props.data.button.label), 1))
        : (props.data.useCloseButton)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "close",
              onClick: removeItem
            }, _toDisplayString(_unref($t)('닫기')), 1))
          : _createCommentVNode("", true)
    ])
  ], 6))
}
}

})