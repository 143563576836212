import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "ui-loader" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { key: 4 }
const _hoisted_7 = { key: 5 }

import { computed } from 'vue';
import uhooWaveLoadingJson from '@/assets/animations/uhoo-wave-loading.json';
import UiLottie from '@/components/commons/utils/Lottie.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Loader',
  props: {
  type: {
    type: String,
    default: 'basic',
  },
  size: {
    type: Number,
    default: 1.0,
  },
},
  setup(__props) {

const props = __props;
const classNames = computed(() => {
  const classNames = ['animation'];
  classNames.push(props.type);
  return classNames;
});

const style = computed(() => {
  return `transform: scale(${props.size}, ${props.size});`;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "loader-wrapper",
      style: _normalizeStyle(style.value)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["spinner", classNames.value])
      }, [
        (__props.type == 'cube')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2))
          : _createCommentVNode("", true),
        (__props.type == 'cube')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3))
          : _createCommentVNode("", true),
        (__props.type == 'cube')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4))
          : _createCommentVNode("", true),
        (__props.type == 'cube')
          ? (_openBlock(), _createElementBlock("div", _hoisted_5))
          : _createCommentVNode("", true),
        (__props.type == 'cube')
          ? (_openBlock(), _createElementBlock("div", _hoisted_6))
          : _createCommentVNode("", true),
        (__props.type == 'cube')
          ? (_openBlock(), _createElementBlock("div", _hoisted_7))
          : _createCommentVNode("", true),
        (__props.type === 'wave')
          ? (_openBlock(), _createBlock(UiLottie, {
              key: 6,
              ref: "lottie-loading",
              animating: true,
              loop: true,
              width: 56,
              height: 56,
              "animation-data": _unref(uhooWaveLoadingJson)
            }, null, 8, ["animation-data"]))
          : _createCommentVNode("", true)
      ], 2)
    ], 4)
  ]))
}
}

})