import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BlockRenderer = _resolveComponent("BlockRenderer", true)!
  const _component_NotionBlock = _resolveComponent("NotionBlock")!

  return ($props.blockMap && _ctx.value)
    ? (_openBlock(), _createBlock(_component_NotionBlock, _normalizeProps(_mergeProps({ key: 0 }, _ctx.pass)), {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.value.content, (contentId, contentIndex) => {
            return (_openBlock(), _createBlock(_component_BlockRenderer, _mergeProps({ ref_for: true }, _ctx.pass, {
              key: contentId,
              level: $props.level + 1,
              "content-id": contentId,
              "content-index": contentIndex
            }), null, 16, ["level", "content-id", "content-index"]))
          }), 128))
        ]),
        _: 1
      }, 16))
    : _createCommentVNode("", true)
}