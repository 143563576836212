// @PENDING: DATA 공간 시설 정보 목록(agent)
// export const spaceFacilityList = [
//   {
//     code: 'PARKING',
//     label: '주차장',
//     input: '주차가 가능한가요?',
//     icon: 'facility-parking',
//   },
//   {
//     code: 'WIDE_PARKING',
//     label: '넓은 주차장',
//     input: '주차장이 넓은가요?',
//     icon: 'facility-parking',
//   },
//   {
//     code: 'BOX_TRUCK_ACCESS',
//     label: '탑차 진입',
//     input: '탑차가 진입할 수 있나요?',
//     icon: 'facility-parking',
//   },
//   {
//     code: 'PLUG',
//     label: '전기 사용',
//     input: '전기 사용이 가능한가요?',
//     icon: 'facility-plug',
//   },
//   // {
//   //   code: 'DOORLOCK',
//   //   label: '보안장치',
//   //   input: '보안장치가 있나요?',
//   //   icon: 'facility-doorlock',
//   // },
//   {
//     code: 'SECURITY_DEVICE',
//     label: '보안장치',
//     input: '보안장치가 있나요?',
//     icon: 'facility-doorlock',
//   },
//   {
//     code: 'CCTV',
//     label: 'CCTV',
//     input: 'CCTV가 있나요?',
//     icon: 'facility-cctv',
//   },
//   {
//     code: 'ELEVATOR',
//     label: '엘리베이터',
//     input: '엘리베이터가 있나요?',
//     icon: 'facility-elevator',
//   },
//   {
//     code: 'FRIDGE',
//     label: '냉장 보관',
//     input: '냉장 보관이 가능한가요?',
//     icon: 'facility-fridge',
//   },
//   {
//     code: 'FREEZER',
//     label: '냉동 보관',
//     input: '냉동 보관이 가능한가요?',
//     icon: 'facility-freezer',
//   },
//   {
//     code: 'WIFI',
//     label: '인터넷(WiFi)',
//     input: 'WiFi 사용이 가능한가요?',
//     icon: 'facility-internet',
//   },
//   {
//     code: 'LAN',
//     label: '인터넷(LAN)',
//     input: '인터넷(LAN) 사용이 가능한가요?',
//     icon: 'facility-internet',
//   },
//   {
//     code: 'TOILET',
//     label: '화장실',
//     input: '화장실 사용이 가능한가요?',
//     icon: 'facility-toilet',
//   },
//   {
//     code: 'FITTING_ROOM',
//     label: '탈의실',
//     input: '탈의실 사용이 가능한가요?',
//     icon: 'facility-fitting_room',
//   },
//   {
//     code: 'PRIME_LOCATION',
//     label: '최고의 위치',
//     input: '',
//     // icon: 'facility-location',
//   },
//   {
//     code: 'GOOD_TRANSPORT',
//     label: '교통 편리성',
//     input: '',
//     // icon: 'facility-location',
//   },
//   {
//     code: 'PRIVATE_SPACE',
//     label: '개별공간',
//     input: '',
//     // icon: 'facility-private_space',
//   },
//   {
//     code: 'COMMON_SPACE',
//     label: '공용공간',
//     input: '',
//     // icon: 'facility-common_space',
//   },
//   {
//     code: 'BUILDING',
//     label: '빌딩',
//     input: '',
//     // icon: 'facility-building',
//   },
//   {
//     code: 'DETACHED_BUILDING',
//     label: '단독건물',
//     input: '',
//     // icon: 'facility-detached_building',
//   },
//   {
//     code: 'CONTAINER',
//     label: '컨테이너',
//     input: '',
//     // icon: 'facility-container',
//   },
//   {
//     code: 'LOGISTIC_WAREHOUSE',
//     label: '물류창고',
//     input: '',
//     // icon: 'facility-warehouse',
//   },
//   {
//     code: 'ETC',
//     label: '기타',
//     input: '',
//     // icon: 'facility-etc',
//   },
// ];

// @DATA 공간 시설 정보 목록(main)
export const spaceFacilityList = [
  {
    code: 'PARKING',
    label: '주차장',
    input: '주차가 가능한가요?',
    icon: 'facility-parking',
    group: 'facility',
  },
  {
    code: 'PLUG',
    label: '전기 사용',
    input: '전기 사용이 가능한가요?',
    icon: 'facility-plug',
    group: 'facility',
    type: 'is',
  },
  {
    code: 'DOORLOCK',
    label: '보안장치',
    input: '보안장치가 있나요?',
    icon: 'facility-doorlock',
    group: 'security',
  },
  {
    code: 'CCTV',
    label: 'CCTV',
    input: 'CCTV가 있나요?',
    icon: 'facility-cctv',
    group: 'security',
  },
  {
    code: 'ELEVATOR',
    label: '엘리베이터',
    input: '엘리베이터가 있나요?',
    icon: 'facility-elevator',
    group: 'facility',
  },
  {
    code: 'FRIDGE',
    label: '냉장 보관',
    input: '냉장 보관이 가능한가요?',
    icon: 'facility-fridge',
    group: 'facility',
  },
  {
    code: 'FREEZER',
    label: '냉동 보관',
    input: '냉동 보관이 가능한가요?',
    icon: 'facility-freezer',
    group: 'facility',
  },
  {
    code: 'WIFI',
    label: '인터넷',
    input: '인터넷 사용이 가능한가요?',
    icon: 'facility-wifi',
    group: 'facility',
  },
  {
    code: 'TOILET',
    label: '화장실',
    input: '화장실 사용이 가능한가요?',
    icon: 'facility-toilet',
    group: 'facility',
  },
  {
    code: 'FITTING_ROOM',
    label: '탈의실',
    input: '탈의실 사용이 가능한가요?',
    icon: 'facility-fitting_room',
    group: 'facility',
  },
];

// @DATA 공간 크기 선택지 목록
export const spaceSizeList = [
  {
    label: '1평 미만',
    value: '-1',
  },
  {
    label: '1~3평',
    value: '1-3',
  },
  {
    label: '4~10평',
    value: '4-10',
  },
  {
    label: '10평 이상',
    value: '10-',
  },
];

// @DATA 공간 사용기간 선택지 목록
export const periodList = [
  {
    label: '1주',
    value: '1주',
  },
  {
    label: '2주',
    value: '2주',
  },
  {
    label: '3주',
    value: '3주',
  },
  {
    label: '4주',
    value: '4주',
  },
  {
    label: '5주',
    value: '5주',
  },
  {
    label: '6주',
    value: '6주',
  },
  {
    label: '7주',
    value: '7주',
  },
  {
    label: '8주',
    value: '8주',
  },
  {
    label: '9주',
    value: '9주',
  },
  {
    label: '10주',
    value: '10주',
  },
  {
    label: '11주',
    value: '11주',
  },
  {
    label: '12주',
    value: '12주',
  },
  {
    label: '4개월',
    value: '4개월',
  },
  {
    label: '5개월',
    value: '5개월',
  },
  {
    label: '6개월',
    value: '6개월',
  },
];

// @DATA 공간 활용용도 목록
export const utilizationList = {
  URBAN_LOGISTICS: {
    code: 'URBAN_LOGISTICS',
    label: '도심물류',
  },
  MFC: {
    code: 'MFC',
    label: 'MFC(소형 물류 센터)',
  },
  LOGISTIC_WAREHOUSE: {
    code: 'LOGISTIC_WAREHOUSE',
    label: '물류창고',
  },
  STORAGE_HUB: {
    code: 'STORAGE_HUB',
    label: '보관거점',
  },
  DELIVERY_HUB: {
    code: 'DELIVERY_HUB',
    label: '배송거점',
  },
  PICKUP_HUB: {
    code: 'PICKUP_HUB',
    label: '픽업거점',
  },
  BSS: {
    code: 'BSS',
    label: 'BSS (Battery Swap Station)',
  },
  STORAGE: {
    code: 'STORAGE',
    label: '스토리지',
  },
  SELF_SERVICE_LOCKER: {
    code: 'SELF_SERVICE_LOCKER',
    label: '무인락커',
  },
  OFFLINE_MARKETING_HUB: {
    code: 'OFFLINE_MARKETING_HUB',
    label: '오프라인마케팅거점',
  },
};

// @DATA 공간 지역적 특성 목록
export const locationFeatureList = {
  NEAR_RESIDENCE: {
    code: 'NEAR_RESIDENCE',
    label: '거주지인근',
  },
  RESIDENTIAL_AREA: {
    code: 'RESIDENTIAL_AREA',
    label: '주거지역',
  },
  NEAR_OFFICE: {
    code: 'NEAR_OFFICE',
    label: '오피스인근',
  },
  COMMERCIAL_AREA: {
    code: 'COMMERCIAL_AREA',
    label: '상업지역',
  },
  MANUFACTURING_AREA: {
    code: 'MANUFACTURING_AREA',
    label: '공업지역',
  },
  AGRICULTURAL_AREA: {
    code: 'AGRICULTURAL_AREA',
    label: '농업지역',
  },
  DISTRIBUTION_COMMERCIAL_AREA: {
    code: 'DISTRIBUTION_COMMERCIAL_AREA',
    label: '유통상업지역',
  },
  INDUSTRIAL_AREA: {
    code: 'INDUSTRIAL_AREA',
    label: '산업지역',
  },
  LIFE_SERVICE_AREA: {
    code: 'LIFE_SERVICE_AREA',
    label: '생활서비스지역',
  },
  NEAR_HIGHWAY: {
    code: 'NEAR_HIGHWAY',
    label: '고속도로인근',
  },
};

export const daysList = [
  {
    label: '월',
    value: 'MON',
  },
  {
    label: '화',
    value: 'TUE',
  },
  {
    label: '수',
    value: 'WED',
  },
  {
    label: '목',
    value: 'THU',
  },
  {
    label: '금',
    value: 'FRI',
  },
  {
    label: '토',
    value: 'SAT',
  },
  {
    label: '일',
    value: 'SUN',
  },
  {
    label: '휴무',
    value: 'HOL',
  },
];
