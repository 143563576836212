import { defineStore } from 'pinia';
import { UAParser } from 'ua-parser-js';
import { AppApi } from '@/api/AppApi';
import config from '@/config';
import { setDeviceInfo } from '@/plugins/axios';
import { useWNInterface } from '@/plugins/vue-wni';
import { uuid } from '@/utils';
import { createLogger } from '@/utils/logger';
import { StorageModule } from '@/utils/storage';
import { useSessionStore } from './session';

const debug = createLogger('mobidic:stores:app');
debug.enabled = true;
const storage = new StorageModule();

const __APP_APPEARANCE__ = '__APP_APPEARANCE__';
const __APP_FIRST_OPENED_AT__ = '__APP_FIRST_OPENED_AT__';
const __APP_DEVICE_ID__ = '__APP_DEVICE_ID__';

export enum APP_STATUS {
  UNSET = 'UNSET', // init
  LAUNCHING = 'LAUNCHING',
  VISIT_CONTINUE = 'VISIT_CONTINUE', // 이전 로그인 기록이 있음
  VISIT_FIRST = 'VISIT_FIRST', // 첫 방문
  NEED_APP_UPDATE = 'NEED_APP_UPDATE', // 앱 업데이트 필요
  NEED_PASSPORT = 'NEED_PASSPORT', // 가입 필요
  LAUNCHED = 'LAUNCHED', // 온보딩 완료
  EXTERNAL = 'EXTERNAL', // 단일 페이지 직접 접근
  BANNED = 'BANNED', // 블락처리
  ERROR = 'ERROR', // 오류
}

export interface DeviceInfo {
  app_id: string;
  app_version: string;
  os_type: string;
  os_version: string;
  id?: string;
  device_id: string;
  device_type: string;
  device_name: string;
  device_model: string;
  device_locale?: string;
  device_user_agent: string;
  push_token?: string;
  push_allowed?: boolean;
}

enum APP_APPEARANCE {
  UNSET = 'unset',
  DARK = 'dark',
  LIGHT = 'light',
  SYSTEM = 'system',
}

interface AppState {
  config: any;
  initialized: boolean;
  firstOpenedAt: string | null;
  status: APP_STATUS;
  appearance: APP_APPEARANCE;
  deviceInfo: DeviceInfo | null;
  zkpCandidates: Array<any>;
  zkpBits: number;
  initData: any;
}

export const useAppStore = defineStore({
  id: 'app',
  state: (): AppState => ({
    config,
    initialized: false,
    firstOpenedAt: 'UNSET',
    status: APP_STATUS.UNSET,
    appearance: APP_APPEARANCE.UNSET,
    deviceInfo: null,
    zkpCandidates: [],
    zkpBits: 32,
    initData: null,
  }),
  getters: {
    currentAppearance: (state) => {
      debug('getters.appearance', state.appearance);
      const appearance = state.appearance;

      // if (appearance === APP_APPEARANCE.UNSET) {
      //   throw new Error('UNSET APP APPEARANCE');
      // }

      if (appearance === 'system') {
        const isDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: Dark)').matches;
        return isDark ? 'dark' : 'light';
      }

      return appearance;
    },
    isFirstOpened: (state) => {
      debug('getters.firstOpenedAt', state.firstOpenedAt);
      if (state.firstOpenedAt === 'UNSET') {
        throw new Error('UNSET APP FIRST OPENED');
      }

      return state.firstOpenedAt ? true : false;
    },
    devicePushAllowed: (state) => {
      return state.deviceInfo?.push_allowed;
    },
  },
  actions: {
    init() {
      return new Promise(async (resolve, reject) => {
        try {
          let storageAppearance = (await storage.get(__APP_APPEARANCE__)) || 'system';
          if (storageAppearance === 'null') {
            storageAppearance = 'system';
          }
          let storageFistOpendAt = (await storage.get(__APP_FIRST_OPENED_AT__)) || null;
          if (storageFistOpendAt === 'null') {
            storageFistOpendAt = null;
          }

          this.appearance = storageAppearance;
          this.firstOpenedAt = storageFistOpendAt;
          debug('init', { appearance: this.appearance, firstOpenedAt: this.firstOpenedAt });
          resolve(this);
        } catch (e) {
          reject(e);
        }
      });
    },
    setStatus(status) {
      debug('setStatus', status);
      return new Promise(async (resolve, reject) => {
        try {
          this.status = status;
          resolve(this);
        } catch (e) {
          reject(e);
        }
      });
    },
    initialize(payload) {
      debug('initialize', payload);
      return new Promise(async (resolve, reject) => {
        try {
          // if (this.initialized) {
          //   throw new Error('ALREADY INITIALIZED');
          // }
          const deviceInfo = this.deviceInfo;

          const api = new AppApi();
          const res = await api.appControllerInitialize({
            app_id: deviceInfo.app_id,
            app_version: deviceInfo.app_version,
            app_os: deviceInfo.os_type,
            device_id: deviceInfo.device_id,
          });

          const { status, message, data } = res as any;

          if (status === 'ERROR') {
            throw new Error(message || 'Unknown Error');
          }

          const { app, device, zkp_candidates, zkp_bits, update_status } = data;

          this.initialized = true;
          this.zkpCandidates = zkp_candidates;
          this.zkpBits = zkp_bits;
          this.initData = data;
          this.deviceInfo = {
            ...this.deviceInfo,
            ...device,
          };
          resolve(this);
        } catch (e) {
          reject(e);
        }
      });
    },
    /**
     * 디바이스 정보를 로드하는 메서드
     *
     * 네이티브 앱과 웹 환경에서 각각 다르게 동작:
     *
     * 1. 네이티브 앱인 경우:
     * - wnAppInfo 인터페이스를 통해 디바이스 정보를 가져옴
     * - 앱 ID, 버전, OS 타입, 디바이스 ID 등의 정보를 수집
     * - 태블릿/모바일 구분하여 device_type 설정
     *
     * 2. 웹 환경인 경우:
     * - 디바이스 ID가 없으면 새로 생성
     * - UAParser를 사용하여 브라우저/OS 정보 파싱
     * - 웹 환경에 맞는 기본 디바이스 정보 설정
     *
     * @param payload - 사용하지 않는 파라미터
     * @returns Promise<this> - 디바이스 정보가 설정된 store 인스턴스
     */
    loadDeviceInfo(payload) {
      debug('loadDeviceInfo', payload);
      return new Promise(async (resolve, reject) => {
        try {
          if (useWNInterface().isNative) {
            await useWNInterface().execute('wnAppInfo', {
              callback: async (data) => {
                try {
                  const info: DeviceInfo = {
                    app_id: data.appId,
                    app_version: data.appVersion,
                    os_type: data.osType,
                    os_version: data.osVersion,
                    device_id: data.deviceId,
                    device_type: data.isTablet ? 'tablet' : 'mobile',
                    device_name: data.deviceName || '',
                    device_model: data.deviceModel,
                    device_locale: data.deviceLocale,
                    device_user_agent: window.navigator.userAgent,
                    push_token: data.pushToken || '',
                  };
                  this.deviceInfo = info;
                  setDeviceInfo(this.deviceInfo);

                  resolve(this);
                } catch (e) {
                  reject(e);
                }
              },
            });
          } else {
            // for Web
            const deviceId: any = await storage.get(__APP_DEVICE_ID__);

            if (!(await storage.get(__APP_DEVICE_ID__))) {
              await storage.set(__APP_DEVICE_ID__, uuid());
            }

            const deviceInfo = new UAParser(window.navigator.userAgent);

            const info: DeviceInfo = {
              app_id: uuid(),
              app_version: config.version,
              os_type: 'web',
              os_version: deviceInfo.getOS().version,
              device_id: deviceId,
              device_type: 'web',
              device_name: 'Device',
              device_model: deviceInfo.getBrowser().name,
              device_user_agent: window.navigator.userAgent,
            };

            this.deviceInfo = info;

            setDeviceInfo(this.deviceInfo);

            resolve(this);
          }
        } catch (e) {
          reject(e);
        }
      });
    },
    pushRegister(payload) {
      debug('pushRegister', payload);
      return new Promise(async (resolve, reject) => {
        try {
          if (useWNInterface().isNative) {
            await useWNInterface().execute('wnPushRegister', {
              callback: async (data) => {
                const allowed = data?.allowed === 'GRANTED' ? true : false;
                const api = new AppApi();
                const res = (await api.appControllerUpdatePushSetting(this.deviceInfo.id, {
                  push_allowed: allowed,
                  push_token: data.token,
                })) as any;

                this.deviceInfo = {
                  ...this.deviceInfo,
                  ...res.data,
                };
              },
            });
          }
          resolve(this);
        } catch (e) {
          reject(e);
        }
      });
    },
    firstOpen() {
      return new Promise(async (resolve, reject) => {
        try {
          const timestamp: string = new Date().toISOString();
          await storage.set(__APP_FIRST_OPENED_AT__, timestamp);
          this.firstOpenedAt = timestamp;
          resolve(this);
        } catch (e) {
          reject(e);
        }
      });
    },
    setPushAllowed(payload) {
      debug('setPushAllowed', payload);
      return new Promise(async (resolve, reject) => {
        try {
          const api = new AppApi();
          const { data } = (await api.appControllerUpdatePushSetting(this.deviceInfo.id, {
            push_allowed: payload,
            push_token: this.deviceInfo.push_token,
          })) as any;
          this.deviceInfo = {
            ...this.deviceInfo,
            ...data,
          };
          resolve(this);
        } catch (e) {
          reject(e);
        }
      });
    },
    start() {
      return new Promise(async (resolve, reject) => {
        try {
          // if (WNInterface.isNative) {
          const session = useSessionStore();
          const profile = session.profile || {};

          let status = APP_STATUS.UNSET;
          if (profile?.BANNED) {
            status = APP_STATUS.BANNED;
          } else if (!this.isFirstOpened) {
            if (profile?.auth_type === 'phone') {
              status = APP_STATUS.LAUNCHED;
            } else {
              status = APP_STATUS.VISIT_FIRST;
            }
          } else if (profile?.auth_type === 'device') {
            status = APP_STATUS.LAUNCHED;
          } else {
            status = APP_STATUS.LAUNCHED;
          }

          if (this.initData.update_status === 'FORCE_UPDATE') {
            status = APP_STATUS.NEED_APP_UPDATE;
          }

          this.status = status;

          // } else {
          //   this.status = APP_STATUS.LAUNCHED;
          // }

          return resolve(this);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
