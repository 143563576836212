import { createRouter, createWebHistory } from 'vue-router';
import Layout from '@/pages/common/layouts/Layout.vue';
import explore from '@/router/pages/explore';
import home from '@/router/pages/home';
import message from '@/router/pages/message';
import mypage from '@/router/pages/mypage';
import permissionGuard from './guarde/permission.guard';
import ApiTestPage from "@/pages/test/ApiTestPage.vue";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition): any {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
  routes: [
    {
      path: '/',
      component: Layout,
      redirect: { name: 'Home' },
      children: [
        ...home,
        ...explore,
        ...message,
        ...mypage,
        /************* common *************/
        {
          path: '/common/launch/:status',
          component: () => import('@/pages/common/launch/PageLaunch.vue'),
          name: 'Launch',
          meta: { title: '' },
        },

        // host
        {
          path: '/common/host/:id/space',
          component: () => import('@/pages/common/host/PageHostSpaceList.vue'),
          name: 'HostSpaceList',
          meta: { title: '호스트의 등록된 공간' },
        },
        // space
        {
          path: '/common/space/list',
          component: () => import('@/pages/common/space/PageSpaceList.vue'),
          name: 'SpaceList',
          meta: { title: '공간 목록' },
        },
        {
          path: '/common/space/:id',
          alias: '/spaces/:id',
          component: () => import('@/pages/common/space/PageSpaceDetail.vue'),
          name: 'SpaceDetail',
          meta: { title: '공간 정보' },
        },
        // 공간 등록
        {
          path: '/common/space/register/guide',
          component: () => import('@/pages/common/space/register/PageSpaceRegisterGuide.vue'),
          name: 'GuideSpaceRegister',
          meta: { title: '공간 등록 가이드' },
        },
        {
          path: '/common/space/register/intro',
          component: () => import('@/pages/common/space/register/PageSpaceRegisterIntro.vue'),
          name: 'IntroSpaceRegister',
          meta: { title: '공간 등록 소개', auth: true },
        },
        {
          path: '/common/space/register',
          component: () => import('@/pages/common/space/register/PageSpaceRegister.vue'),
          name: 'SpaceRegister',
          meta: { title: '공간 등록', auth: true },
        },
        {
          path: '/common/space/register/completion/:id',
          component: () => import('@/pages/common/space/register/PageSpaceRegisterCompletion.vue'),
          name: 'SpaceRegisterCompletion',
          meta: { title: '공간 등록 완료', auth: true },
        },
        // {
        //   path: '/common/space/register/basic',
        //   component: () => import('@/pages/common/space/register/PageSpaceRegisterBasic.vue'),
        //   name: 'SpaceRegisterBasic',
        //   meta: { title: '공간 기본 정보 등록', auth: true },
        //   children: [
        //     {
        //       path: 'address',
        //       component: () => import('@/pages/common/space/register/PageSpaceRegisterAddressForm.vue'),
        //       name: 'SpaceRegitserAddress',
        //       meta: { title: '공간 주소 입력', auth: true },
        //     },
        //     {
        //       path: 'size',
        //       component: () => import('@/pages/common/space/register/PageSpaceRegisterSizeForm.vue'),
        //       name: 'SpaceRegisterSize',
        //       meta: { title: '공간 크기 입력', auth: true },
        //     },
        //   ],
        // },
        // {
        //   path: '/common/space/register/basic/completion/:id',
        //   component: () => import('@/pages/common/space/register/PageSpaceRegisterCompletion.vue'),
        //   name: 'SpaceRegisterBasicCompletion',
        //   meta: { title: '공간 기본 정보 등록 완료', auth: true },
        // },
        // {
        //   path: '/common/space/register/additional/:id',
        //   component: () => import('@/pages/common/space/register/PageSpaceRegisterAdditional.vue'),
        //   name: 'SpaceRegisterAdditional',
        //   meta: { title: '공간 추가 정보 등록', auth: true },
        //   children: [
        //     {
        //       path: 'media',
        //       component: () => import('@/pages/common/space/register/PageSpaceRegisterFormMedia.vue'),
        //       name: 'SpaceRegisterMedia',
        //       meta: { title: '공간 사진 입력', auth: true },
        //     },
        //     {
        //       path: 'facility',
        //       component: () => import('@/pages/common/space/register/PageSpaceRegisterFormFacility.vue'),
        //       name: 'SpaceRegisterFacility',
        //       meta: { title: '공간 시설 입력', auth: true },
        //     },
        //     {
        //       path: 'tiem-table',
        //       component: () => import('@/pages/common/space/register/PageSpaceRegisterFormTimetable.vue'),
        //       name: 'SpaceRegisterTimetable',
        //       meta: { title: '공간 출입 가능 시간 입력', auth: true },
        //     },
        //     {
        //       path: 'description',
        //       component: () => import('@/pages/common/space/register/PageSpaceRegisterFormDescription.vue'),
        //       name: 'SpaceRegisterDescription',
        //       meta: { title: '공간 추가 설명 입력', auth: true },
        //     },
        //   ],
        // },
        // {
        //   path: '/common/space/register/additional/completion/:id',
        //   component: () => import('@/pages/common/space/register/PageSpaceRegisterAdditionalCompletion.vue'),
        //   name: 'SpaceRegisterAdditionalCompletion',
        //   meta: { title: '공간 추가 정보 등록 완료', auth: true },
        // },
        {
          path: '/common/space/:id/edit',
          component: () => import('@/pages/common/space/edit/PageSpaceEdit.vue'),
          name: 'SpaceEdit',
          meta: { title: '공간 정보 수정', auth: true },
        },
        {
          path: '/common/space/:id/edit/address',
          component: () => import('@/pages/common/space/edit/PageSpaceEditAddress.vue'),
          name: 'SpaceEditAddress',
          meta: { title: '공간 주소 수정', auth: true },
        },
        {
          path: '/common/space/:id/edit/price',
          name: 'SpaceEditPrice',
          component: () => import('@/pages/common/space/edit/PageSpaceEditPrice.vue'),
          meta: { title: '공간 요금 수정', auth: true },
        },
        {
          path: '/common/space/:id/edit/available-period',
          component: () => import('@/pages/common/space/edit/PageSpaceEditAvailablePeriod.vue'),
          name: 'SpaceEditAvailablePeriod',
          meta: { title: '사용 가능 기간 수정', auth: true },
        },
        {
          path: '/common/space/:id/edit/contact-number',
          component: () => import('@/pages/common/space/edit/PageSpaceEditFormContactNumber.vue'),
          name: 'SpaceEditContactNumber',
          meta: { title: '연락처 수정', auth: true },
        },
        {
          path: '/common/space/:id/edit/size',
          component: () => import('@/pages/common/space/edit/PageSpaceEditSize.vue'),
          name: 'SpaceEditSize',
          meta: { title: '공간 크기 수정', auth: true },
        },
        {
          path: '/common/space/:id/edit/description',
          component: () => import('@/pages/common/space/edit/PageSpaceEditDescript.vue'),
          name: 'SpaceEditDescription',
          meta: { title: '공간 이용 공지 수정', auth: true },
        },
        {
          path: '/common/space/:id/edit/facility',
          component: () => import('@/pages/common/space/edit/PageSpaceEditFacility.vue'),
          name: 'SpaceEditFacility',
          meta: { title: '공간 시설 수정', auth: true },
        },
        {
          path: '/common/space/:id/edit/media',
          component: () => import('@/pages/common/space/edit/PageSpaceEditMedia.vue'),
          name: 'SpaceEditMedia',
          meta: { title: '공간 사진 수정', auth: true },
        },
        {
          path: '/common/space/:id/edit/tiem-table',
          component: () => import('@/pages/common/space/edit/PageSpaceEditTimetable.vue'),
          name: 'SpaceEditTimetable',
          meta: { title: '공간 이용시간 수정', auth: true },
        },
        {
          path: '/common/space/:id/book',
          component: () => import('@/pages/common/space/book/PageSpaceBook.vue'),
          name: 'SpaceBook',
          meta: { title: '공간 사용 신청', auth: true },
        },
        // {
        //   path: '/common/space/:id/book/completion',
        //   component: () => import('@/pages/common/space/book/PageSpaceBookCompletion.vue'),
        //   name: 'SpaceReservationCompletion',
        //   meta: { title: '공간 사용 신청 완료', auth: true },
        // },

        // space-request
        {
          path: '/common/space-request/write',
          component: () => import('@/pages/common/space-request/SpaceRequestFormPage.vue'),
          name: 'SpaceRequestWrite',
          meta: { title: '공간 구하기 작성', auth: true },
        },
        {
          path: '/common/space-request/create/completion/:id',
          component: () => import('@/pages/common/space-request/create/PageSpaceRequestCreateCompletion-v2.vue'),
          name: 'SpaceRequestCompletion',
          meta: { title: '공간 구하기 작성 완료', auth: true },
        },
        {
          path: '/common/space-request/list',
          component: () => import('@/pages/common/space-request/PageSpaceRequestList_v2.vue'),
          name: 'SpaceRequestList',
          meta: { title: '공간 구하기 목록' },
        },
        {
          path: '/common/space-request/filter',
          component: () => import('@/pages/common/space-request/PageFilter.vue'),
          name: 'SpaceRequestListFilter',
          meta: { title: '공간 구하기 필터'},
        },
        {
          path: '/common/space-request/:id',
          component: () => import('@/pages/common/space-request/PageSpaceRequestDetail_v2.vue'),
          name: 'SpaceRequestDetail',
          meta: { title: '공간 구하기 상세' },
        },
        {
          path: '/common/space-request/:id/edit',
          component: () => import('@/pages/common/space-request/SpaceRequestFormPage.vue'),
          name: 'SpaceRequestEdit',
          meta: { title: '공간 구하기 수정', auth: true },
        },
        {
          path: '/common/space-request/:id/proposal',
          component: () => import('@/pages/common/space-request/proposal/PageSpaceProposal.vue'),
          name: 'SpaceProposal',
          meta: { title: '공간 제안 하기', auth: true },
        },
        {
          path: '/common/space-request/:id/proposal/completion',
          component: () => import('@/pages/common/space-request/proposal/PageSpaceProposalCompletion.vue'),
          name: 'SpaceProposalCompletion',
          meta: { title: '공간 제안 완료', auth: true },
        },
        {
          path: '/common/space/register/judgement-progress',
          component: () => import('@/pages/common/space/register/PageSpaceRegisterJudgementProgress.vue'),
          name: 'SpaceRegisterJudgementProgress',
          meta: { title: '공간 심사 절차', auth: true },
        },
        {
          path: '/test',
          component: Layout,
          children: [
            {
              path: '01',
              component: () => import('@/pages/test/Test01.vue'),
            },
            {
              path: '02',
              component: () => import('@/pages/test/Test02.vue'),
            },
            {
              path: '03',
              component: () => import('@/pages/test/Test03.vue'),
            },
            {
              path: '04',
              component: () => import('@/pages/test/Test04.vue'),
            },
            {
              path: 'api-test',
              component: ApiTestPage,
            },
          ],
        },
      ],
    },
    {
      path: '/biz',
      redirect: { name: '404' },
      component: Layout,
      children: [
        {
          path: ':provider_type',
          component: () => import('@/pages/biz/PageAcuisitionRegisterSpace.vue'),

          name: 'BizRegisterProvider',
          meta: { title: '공간 등록 랜딩' },
        },
        {
          path: 'register-completion',
          component: () => import('@/pages/biz/PageAcuisitionRegisterSpaceCompletion.vue'),
          name: 'BizRegisterCompletion',
          meta: { title: 'biz 공간 등록 완료' },
        },
      ],
    },
    {
      path: '/:catchAll(.*)',
      component: () => import('@/pages/common/error/404.vue'),
      name: '404',
      meta: { title: 'Error 404' },
    },
  ],
});

let previousRoute = null;
router.beforeEach(async (to, from, next) => {
  if (to.hash) {
    if (from && from.name === to.name && from.path === to.path) {
      next(false);
      return;
    }
  }

  // 온보딩페이지로 리다이렉트 시 로그인 후 딥링크로 페이지로 이동할수 있도록 리다이렉트 정보를 저장
  if (to.name === 'Onboarding') {
    if (from.redirectedFrom) {
      to.redirectedFrom = from.redirectedFrom;
    } else {
      to.redirectedFrom = from;
    }
  }

  previousRoute = from;
  await permissionGuard.checkPermission(to, from);
  return permissionGuard.permissionHandler(next);
});

router.afterEach(async (to) => {});

export function getPreviousRoute() {
  return previousRoute;
}

export default router;
