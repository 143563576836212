import axios from 'axios';
import { compareVersions } from 'compare-versions';
import { register } from 'register-service-worker';
import config from '@/config';

const debug = console;

console.log(config.get('web'));
const isDebug = [
  // 'local',
  'debug',
].includes(process.env.VUE_APP_MODE);

const VERSION_KEY = '__UHOO_VERSION__';

class ServiceWorker {
  loadManifest() {
    return axios
      .get(`${config.get('web')}/manifest.json`, {
        headers: { 'Cache-Control': 'no-cache' },
      })
      .then((response) => {
        if (response.data.version) {
          return response.data;
        } else {
          throw new Error('version is undefined');
        }
      });
  }

  async refreshVersion(reload) {
    debug.log('Unregistering service worker.');

    if ('serviceWorker' in window.navigator) {
      const registration = await window.navigator.serviceWorker.ready;
      await registration.unregister();
      debug.log('Service worker has been unregistered.');

      if (reload) {
        setTimeout(() => {
          window.location.reload();
        }, 1000); // 더 짧은 시간으로 조정
      }
    } else if (reload) {
      window.location.reload();
    }
  }

  registerServiceWorker() {
    if (!isDebug) {
      register('/service-worker.js', {
        ready() {
          debug.log('App is being served from cache by a service worker.');
        },
        registered() {
          debug.log('Service worker has been registered.');
        },
        cached() {
          debug.log('Content has been cached for offline use.');
        },
        updatefound() {
          debug.log('New content is downloading.');
        },
        updated() {
          debug.log('New content is available; please refresh.');
          // alert('새로운 버전이 있습니다. 페이지를 새로고침 해주세요.');
        },
        offline() {
          debug.log('No internet connection found. App is running in offline mode.');
        },
        error(error) {
          debug.error('Error during service worker registration:', error);
        },
      });
    }
  }

  async initialize() {
    if (isDebug) {
      debug.log('Service worker is disabled.');
      return;
    }

    try {
      // 1. 현재 로컬 버전 출력
      const currentVersion = localStorage.getItem(VERSION_KEY);
      console.log(`현재 저장된 버전: ${currentVersion}`);

      // 2. manifest.json 로드
      const data = await this.loadManifest();
      console.log(`매니페스트 버전: ${data.version}`);

      // 3. 두 버전이 다르면 업데이트
      if (currentVersion !== data.version) {
        console.log(`버전 차이 발견: ${currentVersion} -> ${data.version}`);
        localStorage.setItem(VERSION_KEY, data.version);
        alert(`새 버전(${data.version})이 설치되었습니다. 페이지를 새로고침 해주세요.`);
      } else {
        console.log('이미 최신 버전입니다.');
      }

      // 4. 서비스 워커 등록 및 버전 체크 시작
      this.registerServiceWorker();
      this.startVersionCheck();
    } catch (e) {
      console.error('서비스 워커 초기화 오류:', e);
    }
  }

  startVersionCheck() {
    if (this._interval) {
      clearInterval(this._interval);
    }

    // 5분(300초) 주기로 버전 확인
    this._interval = setInterval(() => {
      this.loadManifest()
        .then((data) => {
          const minimumVersion = data['minimum-version'];
          if (compareVersions(config.version, minimumVersion) < 0) {
            this.refreshVersion(true);
          }
        })
        .catch((e) => {
          debug.log('Error while checking version:', e);
        });
    }, 1000 * 300); // 5분 주기
  }
}

const serviceWorker = new ServiceWorker();
serviceWorker.initialize();

export default serviceWorker;
