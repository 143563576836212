import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "page-content" }
const _hoisted_2 = { class: "slogan" }
const _hoisted_3 = {
  key: 0,
  class: "loader"
}
const _hoisted_4 = { key: 0 }

import { computed, onBeforeUnmount, onMounted, reactive } from 'vue';
import UiLoader from '@/components/commons/loader/Loader.vue';
import { $t } from '@/plugins/locale';
import { createLogger } from '@/utils/logger';


export default /*@__PURE__*/_defineComponent({
  __name: 'LaunchLoading',
  setup(__props) {

const debug = createLogger('app:components:resources:launch:LaunchLoading');
const state = reactive({
  _timeout: null,
  loading: false,
  messages: [
    '공간 정보를 불러오는 중이에요~',
    '매칭 정보를 불러오는 중이에요~',
    '호스트를 불러오는 중이에요~',
    '에이전트를 모집하는 중이에요~',
    '당신의 공간의 가치를 높여드려요~',
  ],
  messageIndex: -1,
});
const message = computed(() => {
  if (state.messageIndex >= 0) {
    return state.messages[state.messageIndex];
  }

  return '';
});

function startToShowRandomMessages({ delay = 500 }) {
  state.messages.sort(() => (Math.random() > 0.5 ? 1 : 0));

  state._timeout = setTimeout(() => {
    state.loading = true;
    nextMessage();
  }, delay);
}
function nextMessage() {
  let messageIndex = state.messageIndex + 1;
  if (messageIndex >= state.messages.length) {
    messageIndex = 0;
  }
  state.messageIndex = messageIndex;

  if (state._timeout) {
    clearTimeout(state._timeout);
    state._timeout = null;
  }

  state._timeout = setTimeout(() => {
    state.loading = true;
    nextMessage();
  }, Math.max(Math.random() * 3000, 1000));
}

onMounted(() => {
  startToShowRandomMessages({ delay: 500 });
});

onBeforeUnmount(() => {
  if (state._timeout) {
    clearTimeout(state._timeout);
    state._timeout = null;
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "logo" }, [
      _createElementVNode("img", {
        src: "/imgs/common/logo.png",
        alt: "UHOO"
      })
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_unref($t)('유휴 공간의 변신')), 1)
    ]),
    (state.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(UiLoader, {
            type: "wave",
            size: 1.8
          }),
          (message.value)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_unref($t)(message.value)), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})