export default [
  {
    path: '/home',
    component: () => import('@/pages/home/PageHome.vue'),
    name: 'Home',
    meta: { title: '홈' },
  },
  {
    path: '/home/notifications',
    component: () => import('@/pages/home/PageNotification_v2.vue'),
    name: 'Notification',
    meta: { title: '알림', auth: true },
  },
  {
    path: '/home/notifications/filter-setup',
    component: () => import('@/pages/home/alarm/FilterAlarmSetupPage.vue'),
    name: 'FilterAlarmSetup',
    meta: { title: '알림 필터 설정', auth: true },
  },
];
