import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page error" }
const _hoisted_2 = { class: "page-body" }
const _hoisted_3 = { class: "center-content" }
const _hoisted_4 = { class: "message" }

import { computed } from 'vue';
import { $t } from '@/plugins/locale';


export default /*@__PURE__*/_defineComponent({
  __name: 'LaunchError',
  props: {
  error: {
    type: Object,
    default: () => ({}),
  },
},
  setup(__props) {

const props = __props;

const isNetworkError = computed(() => props.error.message === 'Network Error');
const title = computed(() => (isNetworkError.value ? '네트워크 오류' : '알 수 없는 오류'));
const discript = computed(() => {
  if (isNetworkError.value) {
    return '네트워크 연결이 원활하지 않습니다.\n잠시후 다시 시도해 주세요';
  }
  return '알 수 없는 오류가 발생했습니다.\n잠시후 다시 시도해 주세요';
});

function onClickRetry(e) {
  location.reload();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "error-icon" }, [
          _createElementVNode("img", { src: "/imgs/emoji/emoji-wow.png" })
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h1", null, _toDisplayString(_unref($t)(title.value)), 1),
          _createElementVNode("p", null, _toDisplayString(_unref($t)(discript.value)), 1)
        ]),
        _createElementVNode("button", {
          type: "button",
          class: "btn-filled btn-medium",
          onClick: onClickRetry
        }, _toDisplayString(_unref($t)('다시 시도하기')), 1)
      ])
    ])
  ]))
}
}

})