<template>
  <li class="toast-item" :class="type" :style="{ ...itemPosition }">
    <div class="container">
      <svg-icon name="info-bang" />
      <p class="toast-body">
        {{ $t(message) }}
      </p>
      <button
        v-if="props.data.button"
        class="close"
        @click="handleButtonClick"
      >
        {{ props.data.button.label }}
      </button>
      <button
        v-else-if="props.data.useCloseButton"
        class="close"
        @click="removeItem"
      >
        {{ $t('닫기') }}
      </button>
    </div>
  </li>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue';
import { computed, onMounted, ref } from 'vue';
import SvgIcon from '@/components/commons/SvgIcon.vue';
import { $t } from '@/plugins/locale';
import { useLayoutStore } from '@/stores/modules/layout';
import { ToastItem, ToastType, useToastStore } from '@/stores/modules/toast';

const toastModule = useToastStore();
const layout = useLayoutStore();

const props = defineProps({
  data: {
    type: Object as PropType<ToastItem>,
    default: () => {},
  },
});

const type = ref((props.data.type ?? ToastType.ALERT).toLowerCase());
const message = computed(() => {
  const e = props.data.message as any;

  if (e) {
    if (typeof e === 'string') {
      return e;
    } else if (e.data && e.data.error) {
      return e.data.error;
    } else if (e.error_msg) {
      return e.error_msg;
    } else if (e.details) {
      return e.details;
    } else if (typeof e === 'object') {
      return e.toString();
    }
  }

  return 'Unknown Message';
});
const itemPosition = computed(() => {
  if (toastModule?.margin === null || toastModule?.margin === undefined) {
    return {};
  }

  const margin = {};
  for (const key in toastModule.margin) {
    margin[key] = `${layout.unit(toastModule.margin[key])}px`;
  }

  return margin;
});

// 1. Mount 후 자동 삭제 설정
onMounted(() => {
  if (props.data.duration) {
    setTimeout(removeItem, props.data?.duration ?? 3500);
  }
});

// 2. Toast 제거 함수
function removeItem() {
  toastModule.hide(props.data);
}

// 3. "보러가기" 버튼 클릭 핸들러
function handleButtonClick() {
  if (props.data.button && props.data.button.onClick) {
    props.data.button.onClick(); // 프롭으로 전달된 클릭 핸들러 실행
  }
  removeItem(); // 버튼 클릭 후 Toast 제거
}
</script>

<style lang="scss" scoped>
.toast-item {
  @include fsf;
  top: auto;
  max-width: $max-content-width;
  width: 100%;
  margin: unit(12) auto;
  overflow: visible;

  & .container {
    @include flexbox(start);
    @include shadow-level(shadow2);
    position: relative;
    margin: 0 $page-side-margin;
    padding: unit(8) unit(12);
    min-height: unit(40);
    border-radius: $border-radius-medium;
    background-color: #fff;
    .svg-icon {
      font-size: unit(24);
      margin-bottom: auto;
    }

    .action-button {
      @include text-variant(caption, $color-gray-120);
      margin-left: auto;
      padding: unit(4) unit(12);
      background-color: $color-primary;
      color: white;
      border: none;
      border-radius: unit(4);
      cursor: pointer;

      &:hover {
        background-color: darken($color-primary, 10%);
      }
    }

    button.close {
      @include text-variant(caption, $color-gray-120);
      margin-left: auto;
    }
  }

  .toast-body {
    @include text-variant(body4);
    white-space: pre-line;
    line-height: unit(22);
  }

  & {
    color: #292a2b;
  }

  &.error {
    color: $color-state-error;
  }

  &.success {
    color: $color-state-success;
  }

  &.warning {
    color: $color-state-alert;
  }
}
</style>
