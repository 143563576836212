import { UserApi } from '@/api/UserApi';
import { spaceFacilityList } from '@/data/space';
import { useAnalytics } from '@/plugins/analytics';
import { calcFloorSize, calcPriceBySpaceSize, getSpaceSizeLabel, mappingDistrictMain } from '@/utils/space';

/**
 * 애널리틱스로 전송하는 데이터 변환을 위한 함수 모음
 */

/**
 * user property 업데이트
 * @param propertyName - 업데이트할 프로퍼티명
 * @returns - 유저정보 조회 값
 */
export async function updateUserProperty(propertyName: string) {
  const analytics = useAnalytics();
  const api = new UserApi();
  const { data } = (await api.userControllerGetUserInfo()) as any;

  analytics.setUserProperty(propertyName, data[propertyName]);
  return data;
}

/**
 * 공간 구하기 또는 공간 데이터를 이벤트 속성에 맞게 매핑
 * @param {space:any, seek_space:any} data - 공간 또는 공간구하기 data
 * @returns 매핑된 이벤트 속성 객체
 */
export function getEventProperties(data: { space?: any; seek_space?: any }): {
  space_id;
  space_status;
  space_name;
  space_price;
  space_total_price;
  space_ft;
  space_size;
  space_contact;
  space_facilities;
  space_location;
  space_floor;
  space_images;
  space_timetable;
  space_descript;
  seek_space_id;
  seek_space_title;
  seek_space_location;
  seek_space_ft;
  seek_space_term;
  seek_space_purpose;
  seek_space_budget;
  seek_space_facilities;
  seek_space_timetable;
  seek_space_descript;
} {
  const { space, seek_space } = data;

  const properties = {
    space_id: space?.id,
    space_status: space?.status,
    space_name: space?.space_name,
    space_price: parseFloat(
      calcPriceBySpaceSize(space?.space_data?.specified_width, space?.space_data?.specified_height, space?.dayPrice)
        .unitPrice,
    ),
    space_total_price: calcTotalPriceBySpaceSize(
      space?.space_data?.specified_width,
      space?.space_data?.specified_height,
    ),
    space_ft: calcFloorSize(space?.space_data?.specified_width, space?.space_data?.specified_height)?.py,
    space_size: `${space?.space_data?.specified_width}*${space?.space_data?.specified_height}*${space?.space_data?.specified_depth}`,
    space_contact: space?.notify_phone,
    space_facilities: convertFacilitiesAnalyticsValue(space?.space_info?.facilities),
    space_location: space?.space_address,
    space_floor: space?.space_info?.floor || '없음',
    space_images: convertSpaceImagesAnalyticsValue(space?.resources),
    space_timetable: convertTimeTableAnalyticsValue(space?.time_table),
    space_descript: space?.space_descript,
    seek_space_id: seek_space?.id,
    seek_space_size: seek_space?.space_size,
    seek_space_title: seek_space?.title,
    seek_space_location: `${mappingDistrictMain(seek_space?.unrefined_district_main)} ${
      seek_space?.unrefined_district_sub
    }`,
    seek_space_purpose: seek_space?.purpose,
    seek_space_budget: seek_space?.budget,
    seek_space_facilities: convertFacilitiesAnalyticsValue(seek_space?.facilities),
    seek_space_timetable: convertTimeTableAnalyticsValue(seek_space?.time_table),
    seek_space_descript: seek_space?.descript,
    seek_space_ft: getSpaceSizeLabel(seek_space?.space_size),
    seek_space_term: seek_space?.period,
  };

  return properties;
}

const weekDaySorter = [
  { code: 'MON', label: '월' },
  { code: 'TUE', label: '화' },
  { code: 'WED', label: '수' },
  { code: 'THU', label: '목' },
  { code: 'FRI', label: '금' },
  { code: 'SAT', label: '토' },
  { code: 'SUN', label: '일' },
  { code: 'HOL', label: '공휴일' },
];

/**
 * 타임테이블 이벤트 속성 값 변환
 * @param timeTable
 * @returns
 */
function convertTimeTableAnalyticsValue(timeTable) {
  if (!timeTable) {
    return '미등록';
  }

  const res = weekDaySorter.filter((item) => timeTable[item.code]);
  return res.length ? '등록' : '미등록';
}

/**
 * 이미지 첨부 갯수 이벤트 속성 값 변환
 * @param resources
 * @returns
 */
function convertSpaceImagesAnalyticsValue(resources) {
  if (!resources?.space_images?.length) {
    return '0 첨부';
  }

  return `${resources?.space_images.length} 첨부`;
}

/**
 * 공간 시설정보 이벤트 속성 값 변환
 * @param facilities
 * @returns
 */
function convertFacilitiesAnalyticsValue(facilities) {
  if (!facilities || !facilities.length) {
    return '시설 정보 없음';
  }

  return spaceFacilityList.filter((item) => facilities.includes(item.code)).map((item) => item.label);
}

/**
 * 공간 전체 이용가격 계산
 * @param width
 * @param height
 * @returns
 */
function calcTotalPriceBySpaceSize(width: number, height: number): number {
  const UNIT_PRICE = 100000;
  const COMMISSION = 0.15;
  const calcSize: any = () => {
    const size = calcFloorSize(width, height)?.py;
    return size < 1 ? size : Math.floor(size);
  };
  const size = calcSize();
  const price = size * UNIT_PRICE;
  const pricePerUnit = UNIT_PRICE + UNIT_PRICE * COMMISSION;
  const totalPrice = price + price * COMMISSION;
  return totalPrice;
}

/**
 * 에어브릿지 이벤트 시멘틱 속성 생성
 * @param param 공간 데이터
 * @returns 시멘틱 속성 객체
 */
export function getAirbridgeSemanticAttributes({ product }): {
  productID: string;
  quantity: number;
  currency: string;
  price: number;
  name: string;
} {
  const res = {
    productID: product?.id,
    name: product.space_name,
    quantity: Math.trunc(
      calcFloorSize(product?.space_data?.specified_width, product?.space_data?.specified_height)?.py,
    ),
    currency: 'KRW',
    price: calcTotalPriceBySpaceSize(product?.space_data?.specified_width, product?.space_data?.specified_height),
  };

  return res;
}
