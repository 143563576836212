import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "drawer-backward-header"
}
const _hoisted_2 = { class: "drawer-backward-header__container" }

import type { PropType } from 'vue';
import { computed, nextTick, reactive, ref } from 'vue';
import SvgIcon from '@/components/commons/SvgIcon.vue';
import { useWNInterface } from '@/plugins/vue-wni';
import { DrawerItem, DrawerSize, useDrawerStore } from '@/stores/modules/drawer';
import { useLayoutStore } from '@/stores/modules/layout';


export default /*@__PURE__*/_defineComponent({
  __name: 'DrawerItem',
  props: {
  payload: {
    type: Object as PropType<DrawerItem>,
    required: true,
  },
},
  setup(__props) {

const drawerModule = useDrawerStore();
const layoutStore = useLayoutStore();
const wni = useWNInterface();

const props = __props;
const background = ref(null);
const useCloseButton = computed(() => props.payload?.useCloseButton === true);
const isFull = computed(() => props.payload?.size === DrawerSize.FULL);
const isPresentModal = computed(() => props.payload?.useModal !== false);
const state = reactive({
  keyboard_height: computed(() => layoutStore.keyboardHeight),
  styles: computed(() => {
    if (!wni.isAndroid) {
      return {
        'background-color': props.payload.bgColor,
      };
    }

    return {
      '--keyboard-height': `${state.keyboard_height}px`,
      'background-color': props.payload.bgColor,
    };
  }),
});

async function onMouseDown(e) {
  if (props.payload?.canCloseForModal !== false) {
    await nextTick();

    if (e.target == background.value && e.which === 1) {
      drawerModule.dismiss({ id: props.payload.id });
    }
  }
}

async function onClickClose(e) {
  await nextTick();

  drawerModule.dismiss({ id: props.payload.id });
}

async function onClose(e) {
  drawerModule.dismiss({ id: props.payload.id });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", {
    ref_key: "background",
    ref: background,
    class: _normalizeClass(["drawer-item", { full: isFull.value, modal: isPresentModal.value }]),
    onMousedown: onMouseDown,
    onTouchdown: onMouseDown
  }, [
    _createElementVNode("div", {
      class: "container",
      style: _normalizeStyle(state.styles),
      onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
    }, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(__props.payload.component), {
        id: __props.payload.id,
        class: "contents",
        data: __props.payload.data,
        "onUpdate:data": _cache[0] || (_cache[0] = ($event: any) => ((__props.payload.data) = $event)),
        events: __props.payload.events,
        onClose: onClose
      }, null, 40, ["id", "data", "events"])),
      (useCloseButton.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("button", {
                class: "drawer-backward-header__button",
                "touch-effect": "",
                onClick: onClickClose
              }, [
                _createVNode(SvgIcon, {
                  name: "close",
                  style: {"color":"#545F71"}
                })
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ], 4)
  ], 34))
}
}

})