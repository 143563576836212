import _ from 'lodash';

import { ufirst } from '@/utils';
import { createLogger } from '@/utils/logger';

const debug = createLogger('mobidic:config');

export type MobidicConfigOptions = {
  mode: string;
  brand?: string;
  slogan?: string;
};

interface Connect {
  app_key: string;
  service: string;
  server: string;
}

class MobidicConfig {
  private _mode: string = '';
  private _options: MobidicConfigOptions;
  private _config: any;
  private _connect: Connect = {
    app_key: '',
    service: '',
    server: '',
  };

  get mode() {
    return this._mode;
  }

  get version() {
    return process.env.VUE_APP_VERSION;
  }

  get apiServer() {
    return process.env.VUE_APP_API_SERVER;
  }

  get axiosConfig() {
    return {
      baseURL: this.apiServer,
    };
  }

  get connect() {
    return this._connect;
  }

  _printVersion() {
    const styles = [
      {
        id: 'brand',
        text: this._options.brand ?? `{{ Brand Name }}`,
        style: 'font-family: Helvetica; font-size: 42px; font-weight: 500; margin-top: 16px; padding-right: 8px;',
      },
      {
        id: 'version',
        text: `v.${this.version}`,
        style: 'font-family: Helvetica; font-size: 12px; color: #79D9FC; font-weight: 800;',
      },
      {
        id: 'slogan',
        text: this._options.slogan ?? `{{ Brand Slogan }}`,
        style: 'font-family: Helvetica; font-size: 14px; color: #999999; font-weight: 400; margin-bottom: 24px;',
      },
    ];

    const text: any = {};
    styles.forEach((style) => {
      text[style.id] = style.text;
    });

    const output = [];
    output.push(`%c${text.brand}%c${text.version}`);
    output.push(`%c${text.slogan}`);

    const colors: any[] = [];
    styles.forEach((style) => {
      colors.push(style.style);
    });
  }

  _loadEnvironments() {
    this._config = JSON.parse(process.env.VUE_APP_CONFIG || '{}');
    debug('loadEnvironments', this._config);
  }

  _printMode() {
    const styles = [
      {
        id: 'prefix',
        text: `🎈 This is `,
        style: 'font-family: Helvetica; font-size: 12px; font-weight: 200; margin: 16px 0;',
      },
      {
        id: 'mode',
        text: `${ufirst(this._mode)}`,
        style: 'font-family: Helvetica; font-size: 12px; font-weight: 800; margin: 16px 0;',
      },
      {
        id: 'suffix',
        text: ` Mode.`,
        style: 'font-family: Helvetica; font-size: 12px; font-weight: 200; margin: 16px 0;',
      },
    ];

    const text: any = {};
    styles.forEach((style) => {
      text[style.id] = style.text;
    });

    const output = [];
    output.push(`%c${text.prefix}%c${text.mode}%c${text.suffix}`);

    const colors: any[] = [];
    styles.forEach((style) => {
      colors.push(style.style);
    });
  }

  _getConnect() {
    const value = this._config.connect;

    if (value && process.env.VUE_APP_REMOTE_IP_ADDRESS && value.service.indexOf('{{REMOTE_IP_ADDRESS}}') !== -1) {
      value.service = value.service.replace('{{REMOTE_IP_ADDRESS}}', process.env.VUE_APP_REMOTE_IP_ADDRESS);
      value.server = value.server.replace('{{REMOTE_IP_ADDRESS}}', process.env.VUE_APP_REMOTE_IP_ADDRESS);
      return value;
    }

    return value;
  }

  constructor(options: MobidicConfigOptions) {
    this._options = options;
    this._mode = options.mode;
    this._config = {};

    this._printVersion();
    this._loadEnvironments();
    this._printMode();

    this._connect = this._getConnect();
  }

  get(name: string) {
    const value = _.get(this._config, name);
    debug('get', name, value);
    return value;
  }
}

const mode = process.env.VUE_APP_MODE || process.env.NODE_ENV;
const config = new MobidicConfig({
  mode,
  brand: 'UHOO',
  slogan: '세상에 없는 공간 중개 플랫폼',
});

export default config;
