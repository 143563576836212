export const basicErrorHandler = (error, openAlert) => {
  switch (error.code) {
    // case 'need_authenticate':
    //     if (showDialgRef.current) {
    //         showDialgRef.current({
    //             title: '사용자 인증',
    //             message: '로그인이 필요합니다.\n로그인페이지로 이동하시겠습니까?',
    //             first: {
    //                 text: '네',
    //                 onClick: () => {
    //                     window.location.href = '/auth/login';
    //                 }
    //             },
    //             second: {
    //                 text: '닫기',
    //             }
    //         });
    //     }
    //     else showAlert(error.message);

    //     break;

    case 'need_authenticate':
      showAlert('인증이 필요합니다.', openAlert);
      break;
    case 'unauthorized':
      showAlert('권한이 없습니다.', openAlert);
      break;
    case '404':
    default:
      showAlert(error.message, openAlert);
  }
};

const showAlert = (message, openAlert) => {
  if (openAlert) openAlert(message);
  else alert(message);
};
