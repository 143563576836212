import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "page-content" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "page-content" }
const _hoisted_5 = { class: "slogan" }

import { onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';
import PageActions from '@/components/commons/layout/PageActions.vue';
import { $t } from '@/plugins/locale';
import { useAppStore } from '@/stores/modules/app';
import logger from '@/utils/logger';


export default /*@__PURE__*/_defineComponent({
  __name: 'LaunchVisitFirst',
  setup(__props) {

const router = useRouter();
const app = useAppStore();
const debug = logger('app:components:resources:landing:LaunchVisitFirst');

const state = reactive({
  loading: false,
  inviteCode: '',
  inviteUserName: '',
  // inviteCode: '1234', inviteUserName: '아무개아무개아무개아',
});

onMounted(() => {});

function onClickInviteConfirm() {
  debug('onClickInviteConfirm');

  onInviteConfirm();
}

function onClickInviteSkip() {
  debug('onClickInviteSkip');

  // onClickNext();
}

async function onClickStart() {
  debug('onClickStart');

  // useAnalytics().event('click_first_start');
  await onAppStart();
}

async function onInviteConfirm() {
  debug('onInviteConfirm');
  try {
    const options = { code: state.inviteCode };
    // await $store.dispatch("campaign/onBoardingInvite", options)

    await onAppStart();
  } catch (e) {
    console.error(e);
  }
}

async function getUserCode() {
  debug('getUserCode');

  try {
    // const options = {
    //   code: this.$data.inviteCode,
    // };
    // const data = await this.$store.dispatch('campaign/inviteCodeCheck', options);
    // const { user } = data;
    // this.$data.inviteUserName = user.display_name;
  } catch (e) {
    console.error(e);
  }
}

async function onInit() {
  debug('onInit');

  const pathname = location.pathname;

  if (pathname.indexOf('/share/invite/') > -1) {
    state.inviteCode = pathname.split('/share/invite/')[1];

    await getUserCode();
  }
}

async function onAppStart() {
  debug('onAppStart');

  try {
    state.loading = true;

    await router.push({ name: 'Onboarding' });

    await app.firstOpen();
    await app.start();
  } catch (e) {
    console.error(e);
  } finally {
    state.loading = false;
  }
}

onInit();

return (_ctx: any,_cache: any) => {
  return (state.inviteCode)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createElementVNode("div", _hoisted_1, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "logo" }, [
            _createElementVNode("img", {
              src: "/imgs/common/logo.png",
              alt: "UHOO"
            })
          ], -1)),
          _createElementVNode("h1", _hoisted_2, _toDisplayString(_unref($t)('환영합니다')), 1),
          _createElementVNode("p", {
            class: "message",
            innerHTML: 
          _unref($t)('<em>{inviteUserName}</em>님을 통해<br /> 초대를 받으셨군요?', {
            inviteUserName: state.inviteUserName,
          })
        
          }, null, 8, _hoisted_3)
        ]),
        _createVNode(PageActions, null, {
          default: _withCtx(() => [
            _createElementVNode("button", {
              type: "button",
              class: "btn-large btn-filled",
              onClick: onClickInviteConfirm
            }, [
              _createElementVNode("span", null, _toDisplayString(_unref($t)('예, 유후와 함께 할게요')), 1)
            ]),
            _createElementVNode("button", {
              type: "button",
              class: "btn-text",
              onClick: onClickInviteSkip
            }, [
              _createElementVNode("span", null, _toDisplayString(_unref($t)('아니오, 괜찮아요')), 1)
            ])
          ]),
          _: 1
        })
      ], 64))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createElementVNode("div", _hoisted_4, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "logo" }, [
            _createElementVNode("img", {
              src: "/imgs/common/logo.png",
              alt: "UHOO"
            })
          ], -1)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", null, _toDisplayString(_unref($t)('유휴 공간의 변신')), 1)
          ])
        ]),
        _createVNode(PageActions, null, {
          default: _withCtx(() => [
            _createElementVNode("button", {
              type: "button",
              class: "btn-large btn-filled",
              onClick: onClickStart
            }, [
              _createElementVNode("span", null, _toDisplayString(_unref($t)('지금부터 유후~ 할까요?')), 1)
            ])
          ]),
          _: 1
        })
      ], 64))
}
}

})