import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-content" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "message" }

import { computed } from 'vue';
import { $t } from '@/plugins/locale';
import { useWNInterface } from '@/plugins/vue-wni';
import { useAppStore } from '@/stores/modules/app';
import logger from '@/utils/logger';


export default /*@__PURE__*/_defineComponent({
  __name: 'LaunchNeedToUpdate',
  setup(__props) {

const debug = logger('app:components:resources:launch:LaunchNeedToUpdate');
const app = useAppStore();

const version = computed(() => app.initData.app.app_version_minimum);
const url = computed(() => app.initData.update_link);
const updateStatus = computed(() => app.initData.update_status);

function onClickUpdate(e) {
  if (useWNInterface().isNative) {
    useWNInterface().execute('wnOpenURL', {
      url: url.value,
      exit: true,
    });
  } else {
    window.open(url.value);
  }
}
function onClickSkip(e) {}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("p", { class: "emoji" }, "😶‍🌫️", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_unref($t)('이 버전은 수명을 다했어요')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", null, _toDisplayString(_unref($t)('{version} 으로 앱 업데이트가 필요해요', { version: version.value })), 1)
    ]),
    _createElementVNode("button", {
      type: "button",
      class: "btn-filled btn-medium",
      onClick: onClickUpdate
    }, [
      _createElementVNode("span", null, _toDisplayString(_unref($t)('업데이트 하러 가기')), 1)
    ])
  ]))
}
}

})